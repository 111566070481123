import "./support-state-controller.scss";

const SupportStateController = ({ visibleItems, setVisibleItems, items }) => {
  const addItemsCount = 10;
  const totalItems = items.length;
  const newVisibleItems = totalItems - visibleItems < addItemsCount
    ? totalItems - visibleItems
    : addItemsCount;

  const handleShowMore = () => {
    setVisibleItems(visibleItems + newVisibleItems);
  };

  const handleShowAll = () => {
    setVisibleItems(totalItems);
  };

  return (
    <>
      <div className="pagination-wrapper">
        <span>
          Showing {visibleItems} of {totalItems} total
        </span>
        {visibleItems < totalItems && (
          <>
            <hr className="vertical-hr" />
            <span onClick={handleShowMore}>
              Show&nbsp;
              {newVisibleItems}&nbsp;
              more
            </span>
          </>
        )}
        {visibleItems < totalItems && (
          <>
            <hr className="vertical-hr" />
            <span onClick={handleShowAll}>Show all</span>
          </>
        )}
      </div>
    </>
  );
};

export default SupportStateController;
