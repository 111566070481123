import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "@lynx/ui-components";
import cn from "classnames";

import { statisticAPI } from 'api/stats-api';
import { authThunks } from "../../actions-thunks/auth-thunks.js";
import { NEW_PASSWORD__ERROR } from "reducers/types.js";
import constants from "lib/constants.js";

import "./change-password.scss";

const ChangePasswordBlock = ({ setChangePasswordBlock }) => {
  const dispatch = useDispatch();
  const { currentAccount } = useSelector((state) => state.accounts);
  const { profileData } = useSelector((state) => state.profile);
  const { errorMessage } = useSelector((state) => state.errors);

  const [showPassword, setShowPassword] = useState({ old: false, new: false });
  const [localState, setLocalState] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    enabledSendButton: false,
    passwordErrorMessage: "",
  });

  const {
    oldPassword,
    newPassword,
    newPasswordRepeat,
    enabledSendButton,
    passwordErrorMessage,
  } = localState;

  useEffect(() => {
    if (profileData?.changePasswordStatus === "error") {
      setLocalState((l) => ({
        ...l,
        passwordErrorMessage: errorMessage.find(
          (i) => i.type === NEW_PASSWORD__ERROR
        ).message,
      }));
    }
  }, [profileData, errorMessage]);

  useEffect(() => {
    if (oldPassword && newPassword === newPasswordRepeat && newPasswordRepeat) {
      setLocalState((l) => ({
        ...l,
        enabledSendButton: true,
        passwordErrorMessage: "",
      }));
    } else {
      setLocalState((l) => ({
        ...l,
        enabledSendButton: false,
        passwordErrorMessage: "Passwords do not match",
      }));
    }
  }, [newPassword, newPasswordRepeat, oldPassword]);

  const submitHandler = (e, action) => {
    e.preventDefault();
    if (action === "cancel") {
      setChangePasswordBlock(false);
      return;
    }
    setLocalState((l) => ({
      ...l,
      enabledSendButton: false,
    }));
    statisticAPI.sendStatUsage(currentAccount._id, { page: 'Change password' });
    dispatch(
      authThunks.setNewPasswordTC({
        newpassword: newPassword,
        oldpassword: oldPassword,
        username: profileData.username,
      })
    );
  };

  const passwordErrorMsgFormatting = (message) => {
    if (message.split(" ").find((m) => m === "CU1002")) {
      const { type, validations } = constants.newPasswordValidation;
      return (
        <div>
          <span>{type}</span>
          <ul>
            {validations.map((v) => (
              <li key={v}>{v}</li>
            ))}
          </ul>
        </div>
      );
    }
    return message;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ ...localState, [name]: value });
  };

  const handleCancel = () => {
    document.getElementById("change-password-form-block").reset();
    setChangePasswordBlock(false);
  };

  return (
    <Form
      onSubmit={submitHandler}
      id="change-password-form-block"
      className="change-password-block"
    >
      <Form.Group
        className={cn("form-group-block", "mb-3", {
          "not-match": newPasswordRepeat && !!passwordErrorMessage,
        })}
        controlId="old-password"
      >
        <Form.Label className="form-label">Old password</Form.Label>
        <Form.Control
          type={showPassword.old ? "text" : "password"}
          name="oldPassword"
          value={oldPassword}
          onChange={handleChange}
        />
        <span
          className="see-password"
          onClick={() =>
            setShowPassword({ ...showPassword, old: !showPassword.old })
          }
        >
          {showPassword.old ? <Icon name="eye" /> : <Icon name="eye_off" />}
        </span>
      </Form.Group>

      <Form.Group
        className={cn("form-group-block", "mb-3", {
          "not-match": newPasswordRepeat && !!passwordErrorMessage,
        })}
        controlId="formBasicEmail"
      >
        <Form.Label className="form-label">New password</Form.Label>
        <Form.Control
          type={showPassword.new ? "text" : "password"}
          name="newPassword"
          value={newPassword}
          onChange={handleChange}
        />
        <span
          className="see-password"
          onClick={() =>
            setShowPassword({ ...showPassword, new: !showPassword.new })
          }
        >
          {showPassword.new ? <Icon name="eye" /> : <Icon name="eye_off" />}
        </span>
      </Form.Group>

      <Form.Group
        className={cn("form-group-block", "mb-3", {
          "not-match": newPasswordRepeat && !!passwordErrorMessage,
        })}
        controlId="formBasicPassword"
      >
        <Form.Label className="form-label">Repeat new password</Form.Label>
        <Form.Control
          type={showPassword.new ? "text" : "password"}
          name="newPasswordRepeat"
          value={newPasswordRepeat}
          onChange={handleChange}
        />
        <span
          className="see-password"
          onClick={() =>
            setShowPassword({ ...showPassword, new: !showPassword.new })
          }
        >
          {showPassword.new ? <Icon name="eye" /> : <Icon name="eye_off" />}
        </span>
      </Form.Group>
      <div className="d-flex button-group">
        <Button
          size="md"
          buttonStyle="zimtra-secondary"
          buttonClassName="edit-profile-main-button"
          label="Set new password"
          type="submit"
          disabled={!enabledSendButton}
        />
        <Button
          size="md"
          buttonStyle="zimtra-secondary"
          buttonClassName="edit-profile-main-button"
          type="button"
          label="Cancel"
          onClick={handleCancel}
        />
      </div>
      <div className="not-match-pass-block">
        {newPasswordRepeat && passwordErrorMsgFormatting(passwordErrorMessage)}
      </div>
    </Form>
  );
};

export default ChangePasswordBlock;
