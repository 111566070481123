import { GET_ERRORS, SET_TOAST_STATE } from "./types";

const initialState = {
  errorMessage: null,
  toastState: {show: false, message: "", type: ""},
};

const errorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ERRORS:
      if (state.errorMessage === null)
        return { ...state, errorMessage: [payload] };
      if (state.errorMessage.some((i) => i.type === payload.type)) {
        return {
          ...state,
          errorMessage: state.errorMessage.map((item) => {
            if (item.type === payload.type) return payload;
            return item;
          }),
        };
      } else {
        const newArray = [...state.errorMessage];
        newArray.push(payload);
        return {
          ...state,
          errorMessage: newArray,
        };
      }
    case SET_TOAST_STATE:
      return {
        ...state,
        toastState: payload
      }
    default:
      return state;
  }
};

export default errorReducer;
