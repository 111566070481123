import { utilityFunctions } from "ui/utilities/utilityFunctions";
import { AuthAPI } from "../api/auth-api";
import { setAuthToken } from "../api/utils";
import {
  LOGIN_ERROR,
  LOGOUT_ERROR,
  LOG_IN_TOKEN,
  NEW_PASSWORD__ERROR,
  TO_ACCOUNT_SETUP,
  ZIMTRA_REMEMBER_ME,
} from "../reducers/types";
import {
  listAccountsAC,
  setCurrentAccountAC,
  setCurrentUserAC,
  setErrorAC,
  setIsAuthenticatedAC,
  setLoadingAC,
  setWiresListAC,
  triggerRedirectAC,
  updateProfileAC,
} from "./actions";
import constants from "lib/constants";
import { socketClient } from "store.ts";

const eraseTokens = () => {
  localStorage.removeItem(LOG_IN_TOKEN);
  localStorage.removeItem("webSocketToken");
  localStorage.removeItem("onboardingToken");
  localStorage.removeItem("tradergroupToken");
  localStorage.removeItem("token");
  sessionStorage.removeItem(LOG_IN_TOKEN);
};

//Auth thunks
export const authThunks = {
  loginTC: (userData) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      dispatch(setCurrentUserAC(userData));
      const response = await AuthAPI.login(userData);
      if (response.status === 200) {
        const { token } = response.data;
        if (userData.rememberme) {
          localStorage.setItem(LOG_IN_TOKEN, token);
          localStorage.setItem(ZIMTRA_REMEMBER_ME, "yes");
        } else {
          localStorage.setItem(ZIMTRA_REMEMBER_ME, "no");
          sessionStorage.setItem(LOG_IN_TOKEN, token);
        }
        setAuthToken(token);
        dispatch(updateProfileAC({ username: userData.username }));
        dispatch(setIsAuthenticatedAC(true));
        dispatch(
          triggerRedirectAC({ direction: TO_ACCOUNT_SETUP, status: true })
        );
        dispatch(setErrorAC({ type: LOGIN_ERROR, message: "" }));
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(
          setErrorAC({ type: LOGIN_ERROR, message: "wrong login or password" })
        );
        authThunks.logout();
      } else {
        dispatch(
          setErrorAC({
            type: LOGIN_ERROR,
            message: error.response.data?.message,
          })
        );
      }
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  loginByToken: (token) => async (dispatch) => {
    localStorage.setItem(LOG_IN_TOKEN, token);
    localStorage.setItem(ZIMTRA_REMEMBER_ME, "yes");
    try {
      // await profileAPI.getProfile();
      
      setAuthToken(token);
      dispatch(setIsAuthenticatedAC(true));
      dispatch(
        triggerRedirectAC({ direction: TO_ACCOUNT_SETUP, status: true })
      );
      dispatch(setErrorAC({ type: LOGIN_ERROR, message: "" }));
    } catch (e) {
      authThunks.logout();
      // Remove auth header for future requests
      dispatch(setIsAuthenticatedAC(false));
      dispatch(setCurrentUserAC({}));
      // redirectToLanding(loginizationsPaths.LOGOUT);
    }
  },
  logoutTC: () => async (dispatch) => {
    const eraseTokensFlow = () => {
      // Remove auth header for future requests
      dispatch(setIsAuthenticatedAC(false));
      dispatch(setCurrentUserAC({}));
      dispatch(listAccountsAC(null));
      dispatch(setCurrentAccountAC(null));
      dispatch(setWiresListAC(null));
    };
    try {
      dispatch(setLoadingAC(true));
      eraseTokensFlow();
      authThunks.logout();
      utilityFunctions.redirectToLanding(constants.loginizationsPaths.LOGOUT);
    } catch (error) {
      dispatch(
        setErrorAC({
          type: LOGOUT_ERROR,
          message: error.response.data?.message,
        })
      );
      eraseTokensFlow();
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  logout: () => {
    eraseTokens();
    setAuthToken();
    socketClient.disconnectManager();
  },
  setNewPasswordTC: (userData) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await AuthAPI.newPassword(userData);
      if (response.status === 200) {
        dispatch(updateProfileAC({ changePasswordStatus: "success" }));
        dispatch(setErrorAC({ type: NEW_PASSWORD__ERROR, message: "" }));
      }
    } catch (error) {
      dispatch(updateProfileAC({ changePasswordStatus: "error" }));
      dispatch(
        setErrorAC({
          type: NEW_PASSWORD__ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
