import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addSocketNamespaceAC, removeSocketNamespaceAC } from 'actions-thunks/actions';
import { socketClient } from 'store.ts';

const useSocketNamespace = (currentAccount, nameSpaceName, isInitializedSocket) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!currentAccount || !socketClient.manager || !isInitializedSocket) {
      return;
    }

    try {
      dispatch(addSocketNamespaceAC({
        name: nameSpaceName,
        data: {
          id: currentAccount._id
        }
      }));
    } catch (e) {
      setError(e);
    }

    return () => {
      dispatch(removeSocketNamespaceAC(nameSpaceName));
    }
  }, [currentAccount, dispatch, nameSpaceName, isInitializedSocket]);

  return error;
};

export default useSocketNamespace;