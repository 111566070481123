import React from "react";
import { documentAPI } from "../../../api/document-api";
import TableComponent from "../react-table/TableComponent";
import AttachmentDownload from "./AttachmentDownload";

import "./attachment-table.scss";
import { utilityFunctions } from "../../utilities/utilityFunctions";

const AttachmentTable = ({ src, currentDocument, ...props }) => {
  const [tableData, setTableData] = React.useState(null);

  React.useEffect(() => {
    if (src && src.indexOf("attachment/$D/") === 0) {
      let newSrc = null;
      newSrc = `${src.replace("$D", currentDocument)}`;
      const makeRequest = async () => {
        const response = await documentAPI.getImage(newSrc);
        if (response.status === 200) {
          let mimetype = response.headers["content-type"].split(";")[0];
          let blob = new Blob([response.data], { type: mimetype });
          if (mimetype === "text/csv") {
            const reader = new FileReader();
            reader.onload = function (e) {
              const text = e.target.result;
              setTableData(utilityFunctions.processCSV(text));
            };
            reader.readAsText(blob);
          }
        }
      };
      makeRequest();
    } else {
      setTableData(src);
    }
  }, [src, currentDocument]);



  return (
    <div className={`attachment-table-page-wrapper ${props.export}`}>
      {tableData && <TableComponent data={tableData} />}
      {props.export && (
        <AttachmentDownload src={src} currentDocument={currentDocument} />
      )}
    </div>
  );
};

export default React.memo(AttachmentTable);
