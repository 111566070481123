import React, { useEffect, useState } from "react";

const AttachmentImage = ({
  title,
  alt,
  src,
  currentDocument,
  width,
  height,
}) => {
  const [localState, setLocalState] = useState({
    imageData: null,
    href: "",
  });
  useEffect(() => {
    if (src && src.indexOf("attachment/$D/") === 0) {
      const makeRequest = async () => {
        const formattedImgName = src.split("/").at(-1).split(".")[0];
        const response = await fetch(
          `https://zimtra-publica-data.s3.us-east-2.amazonaws.com/faq-markdown-data/images/${formattedImgName}.png`
        );
        if (response.ok) {
          const blob = await response.blob();
          const base64 = URL.createObjectURL(blob);
          setLocalState({
            imageData: base64,
            href: null,
          });
        } else {
          console.error("Error fetching image:", response.statusText);
        }
      };
      makeRequest();
    } else {
      setLocalState({
        imageData: src,
        href: null,
      });
    }
  }, [src, currentDocument]);

  return (
    <img
      title={title}
      alt={alt}
      src={localState.imageData}
      width={width}
      height={height}
    />
  );
};

export default React.memo(AttachmentImage);
