import {
  GET_ERRORS,
  SET_PROFILE,
  SET_CURRENT_USER,
  SET_IS_AUTHENTICATED,
  SET_LOADING,
  SET_RECEIVED_MESSAGE,
  TRIGGER_REDIRECT,
  UPDATE_PROFILE,
  SET_LIST_OF_DOCUMENTS,
  SET_CURRENT_DOCUMENT,
  REMOVE_DOCUMENT_FROM_STORE,
  SET_WIRE_IMAGE,
  SET_ETB_DOCUMENT,
  SET_FRESHEST_ETB_DOCUMENT,
  SET_ACC_EXT,
  UPDATE_CURRENT_ACCOUNT,
  SET_ACC_REQUESTS,
  SET_WIRES_LIST,
  LIST_ACCOUNTS,
  SET_COUNTRIES,
  SET_CURRENT_ACCOUNT,
  SET_STATUS_HISTORY,
  SET_TOAST_STATE,
  SET_REDIRECT_ITEM,
  SET_POPUP_STATE,
  SET_FIELD_IS_EDITING,
  UPDATE_ACC_REQUEST,
  SET_TOTAL_REQUEST_ITEMS,
  ADD_ACC_REQUESTS,
  ADD_ACC_REQUEST,
  SET_RS_METADATA,
  SET_INVITE_CREDS,
  SET_BURGER_MENU_OPENED,
  SET_PORTAL_WORKFLOW_TYPE,
  SET_PAGINATION_LOADING,
  SET_STATEMENTS,
  SET_MARKETDATA_PROVIDERS,
  SET_MARKET_BUNDLES,
  SET_TRADING_PLATFORMS,
  SAVE_REQUESTED_MARKETS,
  SET_CHAT_MESSAGES,
  SET_CHAT_MESSAGE,
  INITIALIZE_SOCKET,
  UPDATE_IS_INITIALIZE_SOCKET,
  ADD_SOCKET_NAMESPACE,
  REMOVE_SOCKET_NAMESPACE,
  DELETE_CHAT_MESSAGES,
  UPDATE_RS_METADATA,
  UPDATE_REQUESTED_MARKETS,
  REMOVE_ACC_REQUEST,
  SET_SIDEBAR_BLOCK_STATUS,
  SET_CURRENT_PAGE_TITLE,
} from "../reducers/types"

//auth actions
export const setCurrentUserAC = (payload) => ({ type: SET_CURRENT_USER, payload });
export const setIsAuthenticatedAC = (payload) => ({ type: SET_IS_AUTHENTICATED, payload });
export const setReceivedMessageAC = (payload) => ({ type: SET_RECEIVED_MESSAGE, payload });

//utils actions
export const triggerRedirectAC = (payload) => ({ type: TRIGGER_REDIRECT, payload });
export const setLoadingAC = (payload) => ({ type: SET_LOADING, payload });
export const setRedirectItemAC = (payload) => ({ type: SET_REDIRECT_ITEM, payload });
export const setInviteCredsAC = (payload) => ({ type: SET_INVITE_CREDS, payload });
export const setPaginationLoadingAC = (payload) => ({ type: SET_PAGINATION_LOADING, payload });
export const setSidebarBlockStatusAC = (payload) => ({ type: SET_SIDEBAR_BLOCK_STATUS, payload });
export const setCurrentPageTitleAC = (payload) => ({ type: SET_CURRENT_PAGE_TITLE, payload });
//error actions
export const setErrorAC = (payload) => ({ type: GET_ERRORS, payload });
export const setToastStateAC = (payload) => ({ type: SET_TOAST_STATE, payload });

//profile actions
export const setProfileAC = (payload) => ({ type: SET_PROFILE, payload });
export const setPopupStateAC = (payload) => ({ type: SET_POPUP_STATE, payload });
export const updateProfileAC = (payload) => ({ type: UPDATE_PROFILE, payload });
export const setFieldIsEditingAC = (payload) => ({ type: SET_FIELD_IS_EDITING, payload });
export const setPortalWorkflowType = (payload) => ({ type: SET_PORTAL_WORKFLOW_TYPE, payload });
export const setBurgerMenuOpenedAC = (payload) => ({ type: SET_BURGER_MENU_OPENED, payload });

//statements actions
export const setStatementsListAC = (payload) => ({ type: SET_STATEMENTS, payload });

//documents actions
export const setListOfDocumentsAC = (payload) => ({ type: SET_LIST_OF_DOCUMENTS, payload });
export const removeDocumentFromStoreAC = (payload) => ({ type: REMOVE_DOCUMENT_FROM_STORE, payload });
export const setCurrentDocumentAC = (payload) => ({ type: SET_CURRENT_DOCUMENT, payload });
export const setETBDocumentAC = (payload) => ({ type: SET_ETB_DOCUMENT, payload });
export const setFreshestETBDocumentAC = (payload) => ({ type: SET_FRESHEST_ETB_DOCUMENT, payload });

//accounts
export const listAccountsAC = (payload) => ({ type: LIST_ACCOUNTS, payload });
export const setCurrentAccountAC = (payload) => ({ type: SET_CURRENT_ACCOUNT, payload });
export const updateCurrentAccountAC = (payload) => ({ type: UPDATE_CURRENT_ACCOUNT, payload });
export const setCountriesAC = (payload) => ({ type: SET_COUNTRIES, payload });
export const setAccountStatusHistoryAC = (payload) => ({ type: SET_STATUS_HISTORY, payload });
export const setAccountExtAC = (payload) => ({ type: SET_ACC_EXT, payload });
export const setWireImageAC = (payload) => ({ type: SET_WIRE_IMAGE, payload });
export const setWiresListAC = (payload) => ({ type: SET_WIRES_LIST, payload });

//marketdata
export const setMarketdataProvidersAC = (payload) => ({ type: SET_MARKETDATA_PROVIDERS, payload });
export const setMarketBundleAC = (payload) => ({ type: SET_MARKET_BUNDLES, payload });
export const setTradingPlatformAC = (payload) => ({ type: SET_TRADING_PLATFORMS, payload });
export const saveRequestedMarketsAC = (payload) => ({ type: SAVE_REQUESTED_MARKETS, payload });
export const updateRequestedMarketsAC = (payload) => ({ type: UPDATE_REQUESTED_MARKETS, payload });

//requests
export const setTotalRequestItemsAC = (payload) => ({ type: SET_TOTAL_REQUEST_ITEMS, payload });
export const setRiskSettingsMetadataAC = (payload) => ({ type: SET_RS_METADATA, payload });
export const updateRiskSettingsMetadataAC = (payload) => ({ type: UPDATE_RS_METADATA, payload });
export const updateAccRequestAC = (payload) => ({ type: UPDATE_ACC_REQUEST, payload });
export const removeAccRequestAC = (payload) => ({ type: REMOVE_ACC_REQUEST, payload });
export const addAccRequestsAC = (payload) => ({ type: ADD_ACC_REQUESTS, payload });
export const addAccRequestAC = (payload) => ({ type: ADD_ACC_REQUEST, payload });
export const setAccRequestsAC = (payload) => ({ type: SET_ACC_REQUESTS, payload });

//socket
export const updateIsInitializedSocketAC = (payload) => ({ type: UPDATE_IS_INITIALIZE_SOCKET, payload });
export const initializeSocketAC = () => ({ type: INITIALIZE_SOCKET });
export const addSocketNamespaceAC = (payload) => ({ type: ADD_SOCKET_NAMESPACE, payload });
export const removeSocketNamespaceAC = (payload) => ({ type: REMOVE_SOCKET_NAMESPACE, payload });

//chat
export const setChatMessageAC = (payload) => ({ type: SET_CHAT_MESSAGE, payload });
export const setChatMessagesAC = (payload) => ({ type: SET_CHAT_MESSAGES, payload });
export const deleteChatMessagesAC = (payload) => ({ type: DELETE_CHAT_MESSAGES, payload });
