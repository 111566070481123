import Cookies from "js-cookie";
import { profileAPI } from "../api/profile-api";
import { CURRENT_PROFILE_ERROR, LOG_IN_TOKEN, UPDATE_PROFILE_ERROR } from "../reducers/types";
import {
  setProfileAC,
  setErrorAC,
  setLoadingAC,
  updateProfileAC,
} from "./actions";
import { authThunks } from "./auth-thunks";
import { utilityFunctions } from "ui/utilities/utilityFunctions";

//profile thunks
export const profileThunks = {
  getProfileTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.getProfile();
      if (response.status === 200) {
        dispatch(setProfileAC(response.data));
      }
      dispatch(setErrorAC({ type: CURRENT_PROFILE_ERROR, message: "" }));
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(authThunks.logoutTC());
      }
      dispatch(
        setErrorAC({
          type: CURRENT_PROFILE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  updateProfileTC: (profileData) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const newProfileData = { ...profileData };
      const response = await profileAPI.updateProfile(newProfileData);
      if (response.status === 200) {
        dispatch(updateProfileAC(profileData));
      }
      dispatch(setErrorAC({ type: UPDATE_PROFILE_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: UPDATE_PROFILE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  upgradeSimulatorTC: (accounts) => async (dispatch) => {
    const runUpgradeWorkflow = () => {
      const domain = utilityFunctions.getDomainFromHostname(window?.location.hostname);
      Cookies.set("onboardingToken", Cookies.get(LOG_IN_TOKEN), {
        expires: 30,
        domain,
        secure: true,
        sameSite: "None",
      });
      Cookies.remove(LOG_IN_TOKEN, {
        domain,
        secure: true,
        sameSite: "None",
      });
      utilityFunctions.redirectToOnboarding();
    }
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.upgradeSimulator();
      if (response.status === 200) {
        runUpgradeWorkflow()
      }
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400 && data.message === "Simulator is upgraded") {
        const notFinishedOnboardingStatuses = [
          "Registered",
          "Activated",
          "Business deal",
          "Review & agreement",
        ];
        const notSimAccounts = accounts.filter(a => a.softwareType !== "Sim");
        if (notSimAccounts.find(a => notFinishedOnboardingStatuses.includes(a.status))) {
          runUpgradeWorkflow()
        }
      } else {
        dispatch(
          setErrorAC({
            type: UPDATE_PROFILE_ERROR,
            message: error.response.data?.message,
          })
        );
      }
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
