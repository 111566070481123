import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authThunks } from "../../../actions-thunks/auth-thunks";

export const Enter = () => {
  const token = new URLSearchParams(window.location.search).get("token");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authThunks.loginByToken(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
