import Markdown from "markdown-to-jsx";

import AttachmentImage from "../ui-kit/attachment/AttachmentImage";
import AttachmentDownload from "../ui-kit/attachment/AttachmentDownload";
import AccordionComponent from "../ui-kit/accordion/Accordion";
import AttachmentTable from "../ui-kit/attachment/AttachmentTable";

import "./Documents.scss";

const Documents = ({ pickedDocument, hideSummary = false }) => {

  const docbody = () => {
    if (pickedDocument?.format === "MARKUP") {

      return (
        <Markdown
          children={pickedDocument?.body || ""}
          options={{
            overrides: {
              Accordion: {
                component: AccordionComponent,
              },
              img: {
                component: AttachmentImage,
                props: {
                  currentDocument: pickedDocument?.id,
                },
              },
              AttachmentTable: {
                component: AttachmentTable,
                props: {
                  currentDocument: pickedDocument?.id,
                },
              },
              AttachmentDownload: {
                component: AttachmentDownload,
                props: {
                  currentDocument: pickedDocument?.id,
                },
              },
            },
            forceBlock: true,
          }}
        />
      );
    } else return pickedDocument?.body || "";
  };

  return (
    <div className="documents-block">
      <div className="lvl-3-block">
        <div className="lvl-3-main-content">
          {!hideSummary && (
            <div className="lvl-3-summary">{pickedDocument?.summary}</div>
          )}
          <div className="lvl-3-body">{docbody()}</div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
