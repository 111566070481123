import { useEffect, useState, useRef } from 'react';
import { statisticAPI } from 'api/stats-api';

const useStatistic = (accID, data, skip = false) => {
  const responsePromise = useRef(null);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading && !responsePromise?.current && accID && data && !skip) {
        setIsLoading(true);
        try {
          responsePromise.current = statisticAPI.sendStatUsage(accID, {
            page: data
          })
          const result = await responsePromise.current;
          setResponse(result);
          responsePromise.current = null
        } catch (error) {
          setError(error);
        }
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accID, data, skip]);

  const reset = () => {
    responsePromise.current = null;
    setError(null);
    setResponse(null);
    setIsLoading(false);
  };

  return { response, error, isLoading, reset };
};

export default useStatistic;