import { useEffect, useState } from 'react';
import { Modal, Button, Input, RadioGroup, Tooltip, Icon } from '@lynx/ui-components';
import cn from 'classnames';
import { useFormik } from 'formik';
import { object, number } from 'yup';

import { createFormFieldValidation, isRegularCategory } from './helpers'
import { utilityFunctions } from "ui/utilities/utilityFunctions";
import "./styles.scss";

createFormFieldValidation("riskSettingNewRequestValue")

const CreateNewRequestModal = ({ settingData, closeModal, showEditModal, update }) => {
  const [isChangedCategory, setChangedCategory] = useState(false);
  const [eod] = useState([
    { label: 'Permanent', checked: true, value: 'perm' },
    { label: 'Reset end of day (EOD)', checked: false, value: 'eod' },
  ]);
  const [selectedRequestDate, setSelectedRequestDate] = useState(() => (eod[0]?.value || eod[0].label));

  const RiskSettingsSchema = object({
    updatedValue:
      number('Only positive integer numbers are allowed')
        .required('Required field')
        .positive('Only positive integer numbers are allowed')
        .integer('Only positive integer numbers are allowed')
        .typeError('Only positive integer numbers are allowed')
        .riskSettingValue(settingData)
  });

  const formik = useFormik({
    initialValues: {
      updatedValue: '',
    },
    validationSchema: RiskSettingsSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const handleFormSubmit = ({ updatedValue }) => {
    handleInputBlur();
    update(settingData, updatedValue, selectedRequestDate === "eod")
    closeModal();
  }

  const handleInputBlur = (e) => {
    const oldVal = formik.values.updatedValue;
    formik.setFieldValue('updatedValue',
      utilityFunctions.roundToNearest(oldVal, isRegularCategory(settingData) ? 100 : 10));
  }

  useEffect(() => {
    if (isChangedCategory) {
      formik.validateForm();
      setChangedCategory(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedCategory]);

  return (
    <div className="modal-window">
      <Modal className="edit-risk-setting-modal cabinet-modals" overlayClassName="request-overlay" styleType="zimtra-primary" isOpen={showEditModal} hideBlur hasCloseIcon={false} onClickOutside={closeModal}>
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={
            <h2 className="zimtra-h2">New Request</h2>
          }
        />
        <Modal.Body className="modal-inner-body">
          <form onSubmit={formik.handleSubmit}>
            <p className="title">{settingData.label}</p>
            <div className="d-flex align-items-center current-price-row modal-row">
              <div className="left-side">
                current
              </div>
              <div>
                {utilityFunctions.currencyFormatter().format(settingData.settings?.default).replace(/\D00$/, '')}
              </div>
            </div>
            <div className="d-flex align-items-center change-price-row modal-row ">
              <div className="change-text left-side">
                change to
              </div>
              <div className={cn('change-value', { "error-field": formik.errors.updatedValue })}>
                <Input
                  labelClassName="cabinet-input"
                  variant="zimtra-primary"
                  placeholder="New Value"
                  size="short"
                  id="category-value-field"
                  name="updatedValue"
                  onChange={formik.handleChange}
                  onBlur={handleInputBlur}
                  value={formik.values.updatedValue}
                />
                <Tooltip
                  placement='bottom-end'
                  isClosable={false}
                  tooltipClassName='category-value-tooltip'
                  tooltipContentClassName='category-value-tooltip-content'
                  content={
                    settingData ? (
                      <span>
                        Value is rounded to the nearest increment of &nbsp;
                        {isRegularCategory(settingData) ? 100 : 10}
                      </span>
                    ) : null
                  }                >
                  <Icon name="info" />
                </Tooltip>
                {formik.errors.updatedValue ? <div className='error-text'>{formik.errors.updatedValue}</div> : null}
              </div>
            </div>
            {settingData.settings?.hasTypeEOD &&
              <div className="d-flex align-items-center type-eod-row radio-group-container">
                <RadioGroup data={eod} onChange={(item) => {
                  setSelectedRequestDate(item?.value || item.label)
                }} />
              </div>
            }
          </form>
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-between">
          <Button size="md" buttonStyle="button-variant-second" buttonClassName="cabinet-primary-btn" disabled={!formik.isValid || !formik.dirty} onClick={formik.handleSubmit} label="Create" />
          <Button size="md" buttonStyle="button-variant-fourth" buttonClassName="cabinet-secondary-btn" onClick={closeModal} label="Cancel" />
        </Modal.Footer>
      </Modal>
    </div >
  )
};

export default CreateNewRequestModal;