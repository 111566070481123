import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ModalProvider } from "ui/ui-kit/modal/ModalContext";
import store from './store';
import AllRoutes from './routing/routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';


const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ModalProvider>
          <div className="App">
            <AllRoutes />
          </div>
        </ModalProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
