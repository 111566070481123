import {
  SET_LOADING,
  TRIGGER_REDIRECT,
  SET_REDIRECT_ITEM,
  SET_SIDEBAR_BLOCK_STATUS,
  SET_CURRENT_PAGE_TITLE,
} from "./types";

const initialState = {
  loading: false,
  triggerRedirect: {
    direction: "",
    status: false,
  },
  redirectItem: null,
  sidebarBlockCollapsed: true,
  currentPageTitle: null,
};

const utilsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TRIGGER_REDIRECT:
      return {
        ...state,
        triggerRedirect: payload,
      };
    case SET_REDIRECT_ITEM:
      return {
        ...state,
        redirectItem: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_SIDEBAR_BLOCK_STATUS:
      return {
        ...state,
        sidebarBlockCollapsed: payload,
      };
    case SET_CURRENT_PAGE_TITLE:
      return {
        ...state,
        currentPageTitle: payload,
      };
    default:
      return state;
  }
};

export default utilsReducer;
