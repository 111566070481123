import {
  saveRequestedMarketsAC,
  setErrorAC,
  setLoadingAC,
} from "./actions";
import {
  CREATE_CHANGE_MARKET_REQUEST_ERROR,
  UPDATE_CHANGE_MARKETS_REQUEST_ERROR,
} from "reducers/types";
import { requestsAPI } from "api/requests-api";

export const marketdataThunks = {
  updateChangeMarketsRequestTC:
    (accID, requestID, body, completeRequest, action) => async (dispatch) => {
      try {
        dispatch(setLoadingAC(true));
        const actionReponse = await requestsAPI.setRequestStatusAction(
          requestID,
          body,
          action
        );
        if (actionReponse.status === 200) {
          dispatch(
            saveRequestedMarketsAC(
              completeRequest ? completeRequest : actionReponse.data.request
            )
          );
        }
      } catch (error) {
        dispatch(
          setErrorAC({
            type: UPDATE_CHANGE_MARKETS_REQUEST_ERROR,
            message: error.response.data?.message,
          })
        );
      } finally {
        dispatch(setLoadingAC(false));
      }
    },

  createChangeMarketsRequestTC: (accID, body) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await requestsAPI.createDataRequest(accID, body);
      if (response.status === 200 && response.data.success) {
        dispatch(saveRequestedMarketsAC(response.data.request));
      }
    } catch (error) {
      dispatch(
        setErrorAC({
          type: CREATE_CHANGE_MARKET_REQUEST_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
