import {
  SAVE_REQUESTED_MARKETS,
  SET_MARKETDATA_PROVIDERS,
  SET_MARKET_BUNDLES,
  SET_TRADING_PLATFORMS,
  UPDATE_REQUESTED_MARKETS
} from "./types";

const initialState = {
  marketdataProviders: null,
  marketBundles: null,
  tradingPlatforms: null,
  requestedMarketsData: null,
};

const marketdataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MARKETDATA_PROVIDERS:
      return {
        ...state,
        marketdataProviders: payload,
      };
    case SET_MARKET_BUNDLES:
      return {
        ...state,
        marketBundles: payload,
      };
    case SET_TRADING_PLATFORMS:
      return {
        ...state,
        tradingPlatforms: payload,
      };
    case SAVE_REQUESTED_MARKETS:
      return {
        ...state,
        requestedMarketsData: payload,
      };
    case UPDATE_REQUESTED_MARKETS:
      const { id, ...rest } = payload;
      if (state.requestedMarketsData && id === state.requestedMarketsData._id) {
        return {
          ...state,
          requestedMarketsData: {
            ...state.requestedMarketsData,
            ...rest
          }
        }
      }
      else {
        return state;
      }
    default:
      return state;
  }
};

export default marketdataReducer;
