export const documentHelpers = {
  sortNavigationBarArray: (arrayToSort) => {
    const withOrders = [];
    const noOrders = [];
    arrayToSort.forEach((item) => {
      if (typeof item.order === "number") {
        withOrders.push(item);
      } else noOrders.push(item);
    });
    withOrders.sort((a, b) => {
      if (a.order !== b.order) return a.order - b.order;
      return a.summary - b.summary;
    });
    noOrders.sort((a, b) => a.summary?.localeCompare(b.summary));
    return withOrders.concat(noOrders);
  },
};
