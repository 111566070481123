import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom"; // Replacing withRouter
import { documentThunks } from "../../../actions-thunks/documents-thunks";

const Permalink = () => {
  const dispatch = useDispatch();
  const location = useLocation(); // Getting location object
  
  useEffect(() => {
    const code = location.pathname.split("/").pop(); // Extracting the last segment of the pathname
    dispatch(documentThunks.getAndDownloadPermalinkTC(code));
  }, [dispatch, location]);

  return null; // No UI rendering needed
};

export default Permalink;
