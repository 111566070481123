import axios from "axios";

import { accountAPI } from "../api/account-api";
import {
  CURRENT_ACCOUNT_ERROR,
  CLAIM_WIRE_ERROR,
  REMOVE_WIRE_CONFIRMATION_IMAGE_ERROR,
  GET_WIRE_CONFIRMATION_IMAGE_ERROR,
  SEND_WIRE_CONFIRMATION_IMAGE_ERROR,
  ACCOUNTS_LIST_ERROR,
  SET_WIRE_LIST_ERROR,
  SET_ACC_EXT_ERROR,
  UPDATE_ACCOUNT_ERROR,
  SET_ACC_STATUS_HISTORY_ERROR,
  SEND_ACCOUNT_SETUP_DATA_ERROR,
  SET_ENTITY_ERROR,
  SET_ACCOUNT_ATTACHMENTS_ERROR,
  GET_ACC_WEBSOCKET_TOKEN_ERROR,
  GET_ACCOUNT_ATTACHMENT_ERROR,
} from "../reducers/types";
import {
  listAccountsAC,
  setCurrentAccountAC,
  setErrorAC,
  setAccountStatusHistoryAC,
  setLoadingAC,
  setAccountExtAC,
  setWireImageAC,
  setWiresListAC,
  setCountriesAC,
  setPortalWorkflowType,
  initializeSocketAC,
  setMarketdataProvidersAC,
  setMarketBundleAC,
  setTradingPlatformAC,
} from "./actions";
import { authThunks } from "./auth-thunks";
import constants from "lib/constants";
import { AuthAPI } from "api/auth-api";

//account thunks
export const accountThunks = {
  listAccountsTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.getAccounts();
      if (response.status === 200) {
        if (response.data?.items?.length) {
          dispatch(listAccountsAC(response.data?.items));
        } else {
          dispatch(listAccountsAC([]));
        }
      }
      dispatch(setErrorAC({ type: ACCOUNTS_LIST_ERROR, message: "" }));
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(authThunks.logoutTC());
      }
      dispatch(
        setErrorAC({
          type: ACCOUNTS_LIST_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  setCurrentAccountTC: (account) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.getAccount(account);
      if (response.data.account) {
        dispatch(setCurrentAccountAC(response.data.account));
      }
      dispatch(setErrorAC({ type: CURRENT_ACCOUNT_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: CURRENT_ACCOUNT_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  updateAccountInfoTC: (accID, data, currentAccount) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.updateAccountInfo(accID, data);
      if (response.status === 200) {
        dispatch(setCurrentAccountAC({ ...currentAccount, ...data }));
      }
      dispatch(setErrorAC({ type: UPDATE_ACCOUNT_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: UPDATE_ACCOUNT_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getAccountAttachmentTC:
    (accID, attachment, disableLoader = false, cancelToken) =>
    async (dispatch) => {
      try {
        !disableLoader && dispatch(setLoadingAC(true));
        const attachmentResponse = await accountAPI.getAttachment(
          accID,
          attachment._id,
          cancelToken
        );
        !disableLoader && dispatch(setLoadingAC(false));
        dispatch(
          setErrorAC({
            type: GET_ACCOUNT_ATTACHMENT_ERROR,
            message: "",
          })
        );
        return {
          info: attachment,
          type: attachmentResponse.headers["content-type"].split("/")[1],
          file: attachmentResponse,
        };
      } catch (error) {
        !disableLoader && dispatch(setLoadingAC(false));
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          dispatch(
            setErrorAC({
              type: GET_ACCOUNT_ATTACHMENT_ERROR,
              message: error.response.data?.message,
            })
          );
        }
      }
    },
  setAccountAttachmentsTC:
    (accID, { formData }) =>
    async (dispatch) => {
      try {
        dispatch(setLoadingAC(true));
        const attachmentsResponse = await accountAPI.setAttachments(
          accID,
          formData
        );
        dispatch(setLoadingAC(false));
        dispatch(
          setErrorAC({
            type: SET_ACCOUNT_ATTACHMENTS_ERROR,
            message: "",
          })
        );
        return attachmentsResponse;
      } catch (error) {
        dispatch(setLoadingAC(false));
        dispatch(
          setErrorAC({
            type: SET_ACCOUNT_ATTACHMENTS_ERROR,
            message: error.response.data?.message,
          })
        );
      }
    },
  sendAccountSetupData:
    (accID, dataForUpdate, dataForReview, dataForStatus, currentAccount) =>
    async (dispatch) => {
      try {
        dispatch(setLoadingAC(true));
        const result = await Promise.all(
          [dataForUpdate, dataForReview, dataForStatus].map(
            async (data, index) => {
              if (index === 0)
                return await accountAPI.updateAccountInfo(accID, data);
              if (index === 1)
                return await accountAPI.updateAccountReview(accID, data);
              if (index === 2)
                return await accountAPI.updateAccountStatus(accID, data);
              else return null;
            }
          )
        );
        if (result.every((r) => r.data.success)) {
          const newCurrentAccount = { ...currentAccount, status: "Updated" };
          dispatch(setCurrentAccountAC(newCurrentAccount));
          dispatch(
            setPortalWorkflowType(constants.portalWorkflowType.AccountOverview)
          );
        }
        dispatch(
          setErrorAC({ type: SEND_ACCOUNT_SETUP_DATA_ERROR, message: "" })
        );
      } catch (error) {
        dispatch(
          setErrorAC({
            type: SEND_ACCOUNT_SETUP_DATA_ERROR,
            message: error.response?.data?.message,
          })
        );
      } finally {
        dispatch(setLoadingAC(false));
      }
    },
  setEntityTC:
    (entity = "country") =>
    async (dispatch) => {
      try {
        dispatch(setLoadingAC(true));
        const response = await accountAPI.getSupportiveEntities(entity);
        if (response.status === 200) {
          if (entity === "country") {
            dispatch(setCountriesAC(response.data.countries));
          }
          if (entity === "marketdataproviders") {
            dispatch(
              setMarketdataProvidersAC(response.data.marketdataproviders || [])
            );
            dispatch(setMarketBundleAC(response.data.bundle || []));
          }
          if (entity === "tradingplatforms") {
            dispatch(setTradingPlatformAC(response.data.tradingplatforms));
          }
        }
        dispatch(setErrorAC({ type: SET_ENTITY_ERROR, message: "" }));
      } catch (error) {
        dispatch(
          setErrorAC({
            type: SET_ENTITY_ERROR,
            message: error.response.data?.message,
          })
        );
      } finally {
        dispatch(setLoadingAC(false));
      }
    },
  getAccountStatusHistoryTC: (accID) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.getStatusHistory(accID);
      if (response.status === 200) {
        dispatch(setAccountStatusHistoryAC(response.data));
      }
      dispatch(setErrorAC({ type: SET_ACC_STATUS_HISTORY_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_ACC_STATUS_HISTORY_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getAccountWebsocketTokenTC: () => async (dispatch) => {
    try {
      const response = await AuthAPI.getToken();
      localStorage.setItem("webSocketToken", response);
      dispatch(initializeSocketAC());
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_ACC_WEBSOCKET_TOKEN_ERROR,
          message: "Error getting token",
        })
      );
    }
  },
  setAccountExtensionsTC: (accID) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.getExtensionData(accID);
      if (response.status === 200) {
        dispatch(setAccountExtAC(response.data.list));
      }
      dispatch(setErrorAC({ type: SET_ACC_EXT_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_ACC_EXT_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getWiresListTC: (accID) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.getWiresList(accID);
      if (response.status === 200 && response.data.success) {
        dispatch(setWiresListAC(response.data.items));
      }
      dispatch(setErrorAC({ type: SET_WIRE_LIST_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_WIRE_LIST_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  claimWireTC: (accID, image, body) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.claimWire(accID, body);
      if (response.status === 200) {
        if (!!image) {
          dispatch(
            accountThunks.sendWireConfirmationImageTC(
              accID,
              response.data.wire._id,
              image
            )
          );
        }
        dispatch(setWiresListAC([response.data.wire]));
      }
      dispatch(setErrorAC({ type: CLAIM_WIRE_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: CLAIM_WIRE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  sendWireConfirmationImageTC: (accID, wireID, image) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.sendWireImage(accID, wireID, image);
      if (response.status === 200) {
        console.log("successfully sent wire image")
      }
      dispatch(
        setErrorAC({ type: SEND_WIRE_CONFIRMATION_IMAGE_ERROR, message: "" })
      );
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SEND_WIRE_CONFIRMATION_IMAGE_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getWireConfirmationImageTC: (accID, wireID) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.getWireImage(wireID);
      if (response.status === 200) {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onload = () => {
          const image = reader.result;
          let dataReceived = response.headers["content-type"];
          if (dataReceived.split("/")[1] === "pdf")
            dataReceived = "application/pdf";
          dispatch(
            setWireImageAC({
              image: `data:${dataReceived};base64,${image.split(",")[1]}`,
              file: reader,
            })
          );
        };
      }
      dispatch(
        setErrorAC({ type: GET_WIRE_CONFIRMATION_IMAGE_ERROR, message: "" })
      );
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_WIRE_CONFIRMATION_IMAGE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  removeWireConfirmationTC: (accID, wire) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await accountAPI.removeWireImage(wire._id);
      if (response.status === 200) {
        dispatch(setWireImageAC(null));
      }
      const response2 = await accountAPI.removeWire(accID, wire._id);
      if (response2.status === 200) {
        const newWire = { ...wire, status: "pending" };
        dispatch(setWiresListAC([newWire]));
      }
      dispatch(
        setErrorAC({ type: REMOVE_WIRE_CONFIRMATION_IMAGE_ERROR, message: "" })
      );
    } catch (error) {
      dispatch(
        setErrorAC({
          type: REMOVE_WIRE_CONFIRMATION_IMAGE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
