import constants from "lib/constants";
import {
  SET_ACC_REQUESTS,
  SET_RS_METADATA,
  ADD_ACC_REQUEST,
  ADD_ACC_REQUESTS,
  SET_TOTAL_REQUEST_ITEMS,
  UPDATE_ACC_REQUEST,
  REMOVE_ACC_REQUEST,
  UPDATE_RS_METADATA
} from "./types";

const initialState = {
  requestsItems: null,
  riskSettingMetadata: null,
  totalRequestItems: null,
};

const ALL = constants.RequestTypes.ALL;

const requestsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACC_REQUESTS:
      const newRequestsList = state.requestsItems === null
        ? payload
        : { ...state.requestsItems, ...payload }
      return {
        ...state,
        requestsItems:newRequestsList,
        totalRequestItems: newRequestsList[ALL]?.length
      };
    case ADD_ACC_REQUEST:
      const newRequestsArray = state.requestsItems[payload.type] ? [...state.requestsItems[payload.type]] : [];
      const newRequestsArrayAll = state.requestsItems[ALL] ? [...state.requestsItems[ALL]] : [];
      newRequestsArray.push(payload.data);
      newRequestsArrayAll.push(payload.data);
      return {
        ...state,
        requestsItems: {
          ...state.requestsItems,
          [payload.type]: newRequestsArray,
          [ALL]: newRequestsArrayAll,
        },
        totalRequestItems: newRequestsArrayAll,
      };
    case ADD_ACC_REQUESTS:
      const newRequestsItems = { ...state.requestsItems };
      payload.forEach((item) => {
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            if (newRequestsItems.hasOwnProperty(key)) {
              newRequestsItems[key] = newRequestsItems[key].concat(item[key]);
            } else {
              newRequestsItems[key] = item[key];
            }
          }
        }
      });
      return {
        ...state,
        requestsItems: newRequestsItems,
        totalRequestItems: newRequestsItems[ALL]?.length
      };
    case REMOVE_ACC_REQUEST:
      const updatedRequestsItems = { ...state.requestsItems };
      for (const key in updatedRequestsItems) {
        if (updatedRequestsItems.hasOwnProperty(key)) {
          updatedRequestsItems[key] = updatedRequestsItems[key].filter(
            (request) => request._id !== payload.id
          );
        }
      }
      return {
        ...state,
        requestsItems: updatedRequestsItems,
        totalRequestItems: updatedRequestsItems[ALL]?.length
      };
    case UPDATE_ACC_REQUEST:
      const newRequestsItemsPartially = { ...state.requestsItems };
      for (const key in newRequestsItemsPartially) {
        if (newRequestsItemsPartially.hasOwnProperty(key)) {
          const foundRequestIndex = newRequestsItemsPartially[key].findIndex(
            (request) => {
              return request._id === payload.id
            }
          );
          if (foundRequestIndex !== -1) {
            const { id, ...dataChunk } = payload;
            newRequestsItemsPartially[key][foundRequestIndex] = {
              ...newRequestsItemsPartially[key][foundRequestIndex],
              ...dataChunk,
            };
          }
        }
      }
      return {
        ...state,
        requestsItems: newRequestsItemsPartially,
      }
    case SET_RS_METADATA:
      return {
        ...state,
        riskSettingMetadata: payload,
      };
    case UPDATE_RS_METADATA:
      const metadata = state.riskSettingMetadata.find((metadata) => {
        return metadata.name === payload.name
      })
      if (metadata) {
        metadata.settings.default = payload.value;
        return {
          ...state,
          riskSettingMetadata: [...state.riskSettingMetadata],
        };
      }
      return {
        ...state,
      };
    case SET_TOTAL_REQUEST_ITEMS:
      return {
        ...state,
        totalRequestItems: payload,
      };
    default:
      return state;
  }
};

export default requestsReducer;
