import axios from "axios";

export const setAuthToken = token => {
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const getApiUrl = () => {
  if (typeof window === 'undefined') {
    return ''; // Return empty string or handle SSR case if needed
  }

  const host = window.location.hostname;

  if (host === 'portal.zimtra.ky' || host === 'portal.zimtra.com') {
    return 'https://traderapi.at-hub.com/v2/api'; // Production URL
  }
  if (host === 'cloud-stage.portal.zimtra.com') {
    return 'https://stage.zimtra.ky/api'; // Stage URL
  }
  if (host === 'dev.portal.zimtra.at-hub.com' || host.includes('localhost')) {
    return 'https://dev.zimtra.at-hub.com/api'; // Development URL
  }

  return ''; // Default or handle unknown case
};

export const getApiSocketPath = () => {
  const host = window.location.hostname;

  if (host === 'portal.zimtra.ky' || host === 'portal.zimtra.com') {
    return '/v2/api/socket.io/'; // Production URL
  }

  return '/api/socket.io/';
};