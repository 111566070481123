import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@lynx/ui-components";

import { statisticAPI } from "api/stats-api";
import RiskSetting from "./RiskSetting";
import constants from "lib/constants";
import { requestsThunks } from "actions-thunks/requests-thunks";
import { Tooltip } from "react-tooltip";
import { utilityFunctions } from "ui/utilities/utilityFunctions";

const { RequestTypes, notActiveRequestsStates } = constants;

const SettingsList = ({ currentAccount }) => {
  const { requestsItems, riskSettingMetadata } = useSelector(
    (state) => state.requests
  );
  const dispatch = useDispatch();
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    let openedRequests = null;
    const requestedRSItems =
      requestsItems?.[constants.RequestTypes.RISKSETTING];

    if (requestedRSItems) {
      openedRequests = requestedRSItems?.filter(
        (r) => !notActiveRequestsStates.includes(r.status)
      );
    }

    if (riskSettingMetadata) {
      const newSettings = riskSettingMetadata.map((s) => {
        const openedRequest = openedRequests?.find(
          (o) => o.ext?.field === s.name
        );

        if (s.name === openedRequest?.ext?.field) {
          const { ext, sequenceNumber } = openedRequest;
          return {
            ...s,
            settings: {
              ...s.settings,
              default: ext?.oldValue,
              sequenceNumber: sequenceNumber,
              oldValue: ext?.oldValue,
              editable: false,
              isRequested: true,
            },
          };
        }
        if (
          currentAccount.accountType === "zimtrapro" ||
          currentAccount.accountType === "zimtraexpert"
        ) {
          return s;
        } else {
          return {
            ...s,
            settings: {
              ...s.settings,
              editable: true,
              isRequested: false,
            },
          };
        }
      });

      setSettings(newSettings);
    }
  }, [requestsItems, riskSettingMetadata, currentAccount]);

  const createRSRequest = (settingData, newValue, eod) => {
    const newRiskSettings = JSON.parse(JSON.stringify(settings));
    let setting = newRiskSettings.find((s) => s.name === settingData.name);
    if (setting) {
      setting.settings.default = newValue;
      setting.settings.oldValue = settingData.settings.default;
      setting.settings.isRequested = true;
      const body = {
        type: RequestTypes.RISKSETTING,
        ext: {
          field: setting.name,
          value: Number(newValue),
          oldValue: settingData.settings.default,
        },
      };
      if (eod) body.eod = eod;
      statisticAPI.sendStatUsage(currentAccount._id, {
        page: `Create request: Risk Settings: ${setting.label}`,
      });

      dispatch(
        requestsThunks.createDataRequestTC(
          currentAccount._id,
          body,
          RequestTypes.RISKSETTING
        )
      );
    }
  };

  if (settings)
    return (
      <div className="risk-settings-block">
        <h6 className="account-title">
          Risk Settings
          <>
            <Icon id="anchor-tooltip-rs-title" name="info" />
            <Tooltip
              delayShow={250}
              delayHide={400}
              anchorSelect="#anchor-tooltip-rs-title"
              clickable={true}
              interactive={true}
              effect="solid"
            >
              <div className="rs-title-tooltip">
                <span>
                  Risk settings are calculated daily based on start
                  <br /> of day balance. Please refer to the website for risk
                  <br /> ratios&nbsp;
                </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${utilityFunctions.getLandingUrl()}/how-it-works/`}
                >
                  https://zimtra.ky/how-it-works/.
                </a>
              </div>
            </Tooltip>
          </>
        </h6>
        <div className="risk-settings-body">
          {settings.map((riskSetting, index) => {
            return (
              <RiskSetting
                settingData={riskSetting}
                key={index}
                createRSRequest={createRSRequest}
              />
            );
          })}
        </div>
      </div>
    );
  return null;
};

export default SettingsList;
