import React from "react";
import { Accordion } from "react-bootstrap";

import "./Accordion.scss";

const AccordionComponent = ({ children }) => {
  const renderChildren = (childrenToREnder) =>
    childrenToREnder?.map((child, index) => {
      if (child.type === "Item")
        return <AccordionItem key={index} child={child} eventKey={index} />;
      return <div key={index}>{child}</div>;
    });

  return <Accordion flush>{renderChildren(children)}</Accordion>;
};

const AccordionItem = ({ child, eventKey }) => {
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const renderNullOrBody = (dataLoaded) => {
    if (dataLoaded) {
      return child.props.children?.map((child, index) => (
        <div key={index}>{child}</div>
      ));
    }
    return null;
  };
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header onClick={() => setDataLoaded(true)}>
        {child.props.header}
      </Accordion.Header>
      <Accordion.Body>{renderNullOrBody(dataLoaded)}</Accordion.Body>
    </Accordion.Item>
  );
};

export default React.memo(AccordionComponent);
