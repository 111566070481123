import { statementsAPI } from "api/statements-api";
import {
  GET_STATEMENTS_FILE_ERROR,
  GET_STATEMENTS_LIST_ERROR,
} from "../reducers/types";
import {
  setStatementsListAC,
  setErrorAC,
  setLoadingAC,
} from "./actions";

//profile thunks
export const statementsThunks = {
  getStatementsListTC: (accID) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await statementsAPI.getStatements(accID);
      if (response.status === 200 && response.data.success) {
        dispatch(setStatementsListAC(response.data.items));
      }
      dispatch(setErrorAC({ type: GET_STATEMENTS_LIST_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_STATEMENTS_LIST_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getStatementFileTC: (accID, file) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await statementsAPI.getStatementFile(accID, file._id);
      if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const pdfURL = window.URL.createObjectURL(blob);

          let tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = pdfURL;
          tempLink.download = file.filename;
          document.body.appendChild(tempLink);
          tempLink.click();

          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(pdfURL);
      }
      dispatch(setErrorAC({ type: GET_STATEMENTS_FILE_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_STATEMENTS_FILE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
