import { Navigate } from "react-router-dom";

import Enter from "../ui/auth/enter";
import Permalink from "../ui/ui-kit/permalink/Permalink";
import LoginPage from "../ui/auth/login/LoginPage.js";
import EditProfile from "../ui/edit-profile/EditProfilePage.js";
import SupportPage from "../ui/support/SupportPage";
import AccountSetup from "../ui/account-setup/AccountSetup";
import AccountOverview from "../ui/account-overview/AccountOverviewPage";
import Statements from "../ui/statements/StatementsPage";
import MarketDataPage from "../ui/marketdata-page/MarketDataPage.js";
import NotFound from "../ui/not-found/NotFound";
import Simulator from "../ui/simulator/Simulator.js";
import Resources from "../ui/resources/index.js";
import FAQ from "../ui/faq/index.js";
import PrivateRoute from "../ui/auth/PrivateRoute";
import {
  TO_ENTER,
  TO_PERMALINK,
  TO_LOGIN,
  TO_PROFILE,
  TO_SIMULATOR,
  TO_ACCOUNT_SETUP,
  TO_ACCOUNT_OVERVIEW,
  TO_STATEMENTS,
  TO_MARKET_DATA,
  TO_SUPPORT_PAGE,
  TO_RESOURCES,
} from "../reducers/types";

const routesConfig = [
  { path: TO_ENTER, element: <Enter /> },
  { path: TO_PERMALINK, element: <Permalink /> },
  { path: TO_LOGIN, element: <LoginPage />, condition: window.location.hostname === "localhost" },
  { path: TO_PROFILE, element: <PrivateRoute component={EditProfile} allTypesRoute /> },
  { path: TO_SIMULATOR, element: <PrivateRoute component={Simulator} simulatorRoute /> },
  { path: TO_ACCOUNT_SETUP, element: <PrivateRoute component={AccountSetup} notActivatedAccountRoute /> },
  { path: TO_ACCOUNT_OVERVIEW, element: <PrivateRoute component={AccountOverview} overviewRoute /> },
  { path: TO_STATEMENTS, element: <PrivateRoute component={Statements} overviewRoute /> },
  { path: TO_MARKET_DATA, element: <PrivateRoute component={MarketDataPage} overviewRoute /> },
  { path: TO_SUPPORT_PAGE, element: <PrivateRoute component={SupportPage} overviewRoute /> },
  { path: TO_RESOURCES, element: <PrivateRoute component={Resources} allTypesRoute /> },
  { path: "/faq/:documentId", element: <PrivateRoute component={FAQ} allTypesRoute /> },
  { path: "/", element: <Navigate to={TO_ACCOUNT_SETUP} /> },
  { path: "*", element: <NotFound /> },
];

export default routesConfig;
