import {
  SET_CHAT_MESSAGES,
  SET_CHAT_MESSAGE,
  DELETE_CHAT_MESSAGES
} from "./types";

const initialState = {
  messages: [],
};

const chatReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CHAT_MESSAGES:
      return {
        ...state,
        messages: payload,
      };
    case SET_CHAT_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages,
          payload
        ],
      };
    case DELETE_CHAT_MESSAGES:
      return {
        ...state,
        messages: [],
      };
    default:
      return state;
  }
};

export default chatReducer;
