import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Modal, Button, Dropdown, Input, RadioGroup, Tooltip, Icon } from '@lynx/ui-components';
import cn from 'classnames';
import { useFormik } from 'formik';
import { object, number } from 'yup';

import { createFormFieldValidation, isRegularCategory } from './helpers'
import constants from "lib/constants";
import { utilityFunctions } from "ui/utilities/utilityFunctions";

import "./styles.scss";

const { RequestTypes } = constants;

createFormFieldValidation("riskSettingValue")

const CreateRiskSettingsModal = ({ closeModal, updateCategory, categories, activeCategory, submitModal }) => {
  const [selectedRequestDate, setSelectedRequestDate] = useState(null);
  const [isChangedCategory, setChangedCategory] = useState(false);
  const { riskSettingMetadata } = useSelector((state) => state.requests) || [];
  const [disabled, setDisabled] = useState(true);
  const [activeRiskSetting, setRiskSetting] = useState(null);
  const [eod, setEod] = useState([
    { label: 'Permanent', checked: false, value: 'perm' },
    { label: 'Reset end of day (EOD)', checked: false, value: 'eod' },
  ]);
  const updatedRiskSettingMetadata = riskSettingMetadata.filter(item => item.settings.default !== 0).map((item) => ({
    ...item,
    value: item.name
  }));
  const defaultCategory = categories.find(category => category.value === activeCategory.value);

  const RiskSettingsSchema = object({
    updatedValue:
      number('Only positive integer numbers are allowed')
        .required('Required field')
        .positive('Only positive integer numbers are allowed')
        .integer('Only positive integer numbers are allowed')
        .typeError('Only positive integer numbers are allowed')
        .riskSettingValue(activeRiskSetting)
  });

  const formik = useFormik({
    initialValues: {
      updatedValue: '',
    },
    validationSchema: RiskSettingsSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const handleFormSubmit = (values) => {
    const body = {
      type: RequestTypes.RISKSETTING,
      label: activeRiskSetting.label,
      ext: {
        field: activeRiskSetting.name,
        oldValue: activeRiskSetting.settings.default,
        value: values.updatedValue,
      },
      eod: selectedRequestDate.value === 'perm' ? false : true
    };

    submitModal({
      body,
      type: RequestTypes.RISKSETTING
    })
  }

  const handleRiskSettingChange = (riskSetting) => {
    setRiskSetting(() => updatedRiskSettingMetadata.find(item => item.name === riskSetting));
    setDisabled(false);
    setEod((oldVal) => {
      const newEod = [...oldVal];
      newEod[0].checked = true;
      return newEod;
    });

    document.getElementById('category-value-field').dispatchEvent(new Event('change'));
    setChangedCategory(true);
  };

  const handleInputBlur = (e) => {
    const oldVal = formik.values.updatedValue;
    formik.setFieldValue('updatedValue',
      utilityFunctions.roundToNearest(oldVal, isRegularCategory(activeRiskSetting) ? 100 : 10));
  }

  useEffect(() => {
    if (isChangedCategory) {
      formik.validateForm();
      setChangedCategory(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedCategory]);

  useEffect(() => {
    setSelectedRequestDate(() => {
      return eod.find(item => item.checked);
    })
  }, [eod]);

  return (
    <div className="modal-window">
      <Modal className="risk-settings-modal cabinet-modals" overlayClassName="request-overlay" styleType="zimtra-primary" isOpen={true} hideBlur hasCloseIcon={false} onClickOutside={closeModal}>
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={
            <h2 className="zimtra-h2">New Request</h2>
          }
        />
        <Modal.Body className="modal-inner-body">
          <div className="d-flex align-items-top modal-row">
            <div className="left-side">
              Category:
            </div>
            <div>
              <Dropdown options={categories} defaultValue={defaultCategory.value} className="request-dropdown select-category-dropdown" placeholder="Select category" onChange={updateCategory} />
            </div>
          </div>
          <hr />
          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex align-items-top modal-row">
              <Dropdown options={updatedRiskSettingMetadata} className="request-dropdown select-risk-settings-dropdown" placeholder="Select a Risk Settings" onChange={handleRiskSettingChange} />
            </div>
            <div className="d-flex align-items-top current-price-row modal-row">
              <div className="left-side">
                current
              </div>
              <div>
                {activeRiskSetting ? utilityFunctions
                  .currencyFormatter()
                  .format(
                    activeRiskSetting.settings?.value
                      ? activeRiskSetting.settings?.value
                      : activeRiskSetting.settings?.default
                  )
                  .replace(/\D00$/, "") : 'N/A'}
              </div>
            </div>
            <div className={cn('d-flex align-items-top change-price-row modal-row', {
              'disable-row': disabled
            })}>
              <div className="change-text left-side">
                change to
              </div>
              <div className={cn('change-value', { "error-field": formik.errors.updatedValue })}>
                <Input
                  labelClassName="cabinet-input"
                  variant="zimtra-primary"
                  placeholder="New Value"
                  size="short"
                  id="category-value-field"
                  name="updatedValue"
                  onChange={formik.handleChange}
                  onBlur={handleInputBlur}
                  value={formik.values.updatedValue}
                />
                <Tooltip
                  placement='bottom-end'
                  isClosable={false}
                  tooltipClassName='category-value-tooltip'
                  tooltipContentClassName='category-value-tooltip-content'
                  content={
                    activeRiskSetting ? (
                      <span>
                        Value is rounded to the nearest increment of &nbsp;
                        {isRegularCategory(activeRiskSetting) ? 100 : 10}
                      </span>
                    ) : null
                  }                >
                  <Icon name="info" />
                </Tooltip>
                {formik.errors.updatedValue ? <div className='error-text'>{formik.errors.updatedValue}</div> : null}
              </div>
            </div>
            <div className={cn('d-flex align-items-top type-eod-row radio-group-container modal-row', {
              'disable-row': disabled
            })}>
              <RadioGroup data={eod} onChange={(item) => setSelectedRequestDate(item)} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-between">
          <Button size="md" buttonStyle="button-variant-second" buttonClassName="cabinet-primary-btn" disabled={disabled || !formik.isValid} onClick={formik.handleSubmit} label="Create" />
          <Button size="md" buttonStyle="button-variant-fourth" buttonClassName="cabinet-secondary-btn" onClick={closeModal} label="Cancel" />
        </Modal.Footer>
      </Modal>
    </div >
  )
};

export default CreateRiskSettingsModal;
