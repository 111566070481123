import {
  SET_PORTAL_WORKFLOW_TYPE,
  SET_FIELD_IS_EDITING,
  SET_POPUP_STATE,
  SET_PROFILE,
  SET_PROFILE_NOTIFICATIONS,
  UPDATE_PROFILE,
  SET_BURGER_MENU_OPENED,
} from "./types";

const initialState = {
  profileData: null,
  profileAvatar: "/default-avatar.png",
  profileActive: true,
  notifications: [],
  popupState: { opened: false, message: "", type: "" },
  fieldIsEditing: false,
  portalWorkflowType: null,
  burgerMenuOpened: false,
};

const profileReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_PROFILE:
    case UPDATE_PROFILE:
      return {
        ...state,
        profileData: { ...state.profileData, ...payload },
      };
    case SET_PROFILE_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };
    case SET_POPUP_STATE:
      return {
        ...state,
        popupState: payload
      }
    case SET_FIELD_IS_EDITING:
      return {
        ...state,
        fieldIsEditing: payload
      }
    case SET_PORTAL_WORKFLOW_TYPE:
      return {
        ...state,
        portalWorkflowType: payload
      }
      case SET_BURGER_MENU_OPENED:
        return {
          ...state,
          burgerMenuOpened: payload,
        };
    default:
      return state;
  }
};

export default profileReducer;
