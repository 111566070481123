// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { accountThunks } from "../../../actions-thunks/account-thunks";
import { accountAPI } from "../../../api/account-api";
import constants from "../../../lib/constants";
import { reviewStatuses } from "../../../reducers/types";
import { utilityFunctions } from "../../utilities/utilityFunctions";

import "./review-field.scss";

const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
const ALLOWED_IMAGE_EXT = ["jpg", "jpeg", "png", "pdf"];

const ReviewFields = ({ reviews = [] }) => {
  const [localState, setLocalState] = useState({});
  const [localReviewState, setLocalReviewState] = useState([]);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [validFieldsStatus, setValidFieldsStatus] = useState(false);
  const [validFields, setValidFields] = useState({});
  const [touchedFields, setTouchedFields] = useState({})
  const { currentAccount, countries } = useSelector((state) => state.accounts);
  const { loading } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  // detect whether the user filled in all required fields
  useEffect(() => {
    if (
      !!reviews.length &&
      reviews
        .filter((rf) => rf.status === reviewStatuses.DEFICIENT)
        .every((r) => {
          if (!!localState[r.field] && !!touchedFields[r.field]) return true;
          return false;
        })
    ) {
      setAllFieldsFilled(true);
    } else {
      setAllFieldsFilled(false);
    }
  }, [localState, touchedFields, reviews]);

  useEffect(() => {
    if (Object.entries(validFields).length && Object.entries(validFields).every(o => o[1])) {
      setValidFieldsStatus(true);
    } else {
      setValidFieldsStatus(false);
    }
  }, [validFields]);

  // set local reviews and starting values in localState for deficient fields
  useEffect(() => {
    if (!!reviews.length && !!Object.keys(currentAccount).length) {
      setLocalReviewState(reviews);
      let newLocalState = {};
      const deficientReviews = reviews.map(r => r.status === reviewStatuses.DEFICIENT ? r : null).filter(n => n);
      deficientReviews.forEach(r => {
        r.field.split(".").reduce((p, c) => {
          if (typeof p?.[c] === "string") {
            newLocalState[r.field] = p?.[c]
          }
          return p?.[c]
        }, currentAccount);
      });
      if (!!newLocalState["dateofbirth"]) {
        newLocalState["dateofbirth"] = utilityFunctions.nativeDateTransform(newLocalState["dateofbirth"].split("T")[0], true)
      }
      if (!!newLocalState["document.idexpiration"]) {
        newLocalState["document.idexpiration"] = utilityFunctions.nativeDateTransform(newLocalState["document.idexpiration"].split("T")[0], true)
      }
      setLocalState(newLocalState)
    }
  }, [reviews, currentAccount]);

  const onSubmitClick = (e) => {
    e.preventDefault();
    let preparedAccInfoObject = {
      clientID: currentAccount._id,
    };
    const changeRFToUpdated = localReviewState.map((rf) => {
      if (rf.status === reviewStatuses.DEFICIENT)
        return { ...rf, status: reviewStatuses.UPDATED };
      return rf;
    });
    Object.entries(localState).forEach((o) => {
      const splitted = o[0].split(".");
      if (splitted.length > 1) {
        if (splitted[0] === "pep") {
          preparedAccInfoObject = {
            ...preparedAccInfoObject,
            [splitted[0]]: o[1],
          };
        } if (splitted[1] === "scanFiles" && splitted[0] === "document") {
          // this field is empty because we don't need to add image to preparedAccInfoObject because image send data uses other api to be saved
        }
        else {
          preparedAccInfoObject[splitted[0]] = {
            ...preparedAccInfoObject[splitted[0]],
            [splitted[1]]: o[1],
          };
        }
      } else {
        preparedAccInfoObject = {
          ...preparedAccInfoObject,
          [splitted[0]]: o[1],
        };
      }
    });
    dispatch(
      accountThunks.sendAccountSetupData(
        currentAccount._id,
        preparedAccInfoObject,
        changeRFToUpdated,
        { newstatus: "Updated", notes: "" },
        currentAccount
      )
    );
    setValidFields({});
    setValidFieldsStatus(false);
    setAllFieldsFilled(false);
  };
  const handleChange = (name, value) => {
    setLocalState({ ...localState, [name]: value });
    const newCheck = utilityFunctions.testedField(name, value);
    setValidFields({ ...validFields, ...newCheck });
    setTouchedFields({ ...touchedFields, [name]: true });
  };
  const s = (value) => { // fn() to sort review fields according to example in constants.js file
    return constants.SortReviewFieldsExample.indexOf(utilityFunctions.transfromFromNestedField(value))
  }

  const renderReviewFieldItem = reviews
    .sort((a, b) => s(a.field) - s(b.field))
    .filter((rf) => rf.status === reviewStatuses.DEFICIENT)
    .map((rf, index) => {
      return (
        <ReviewFieldItem
          localValue={localState[rf.field]}
          handleChange={handleChange}
          validFields={validFields}
          accID={currentAccount._id}
          item={rf}
          isTouched={touchedFields[rf.field]}
          countries={countries}
          key={rf.field + "-" + index}
        />
      );
    });

  return (
    <div className="review-field-block">
      {renderReviewFieldItem}
      <button onClick={onSubmitClick} disabled={allFieldsFilled ? !validFieldsStatus : true}>
        Submit
      </button>
      {loading && <Spinner className="spinner-button" animation="grow" />}
    </div>
  );
};

export default ReviewFields;

const ReviewFieldItem = ({ item, handleChange, countries = [], validFields, isTouched, localValue, accID }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImageID, setUploadedImageID] = useState(null);
  const [imageInfo, setImageInfo] = useState("");
  const hiddenFileInput = useRef(null);
  const returnErrorMessage = (field) => {
    if (field === 'phone')
      return constants.errorFieldsValues.digitsInvalidField;
    return constants.errorFieldsValues.defaultInvalidField
  }

  const renderDefaultField = (fieldName) => {
    const onDefaultChange = (e) => {
      const { name, value } = e.target;
      handleChange(utilityFunctions.transformToNestedField(name), value);
    };
    return (
      <div className="review-default-field-item">
        <label>{utilityFunctions.changeLabelView(fieldName)}</label>
        <input
          name={item.field}
          type="text"
          onChange={onDefaultChange}
          value={localValue}
        />
        {typeof validFields[item.field] === "boolean" && !validFields[item.field] && (
          <span className={"not-valid-form"}>{returnErrorMessage(fieldName)}</span>
        )}
      </div>
    );
  };
  const renderDateField = (fieldName) => {
    const renderDateValue = utilityFunctions.nativeDateTransform(localValue);
    const changeDataHandler = (e) => {
      let newValue = e.target.value;
      const { name } = e.target;
      newValue = utilityFunctions.nativeDateTransform(newValue, true)
      handleChange(utilityFunctions.transformToNestedField(name), newValue);
    };

    return (
      <div className="review-default-field-item">
        <label>{utilityFunctions.changeLabelView(fieldName)}</label>
        <input
          name={fieldName}
          type="date"
          value={renderDateValue}
          onChange={changeDataHandler}
          max={new Date(new Date().getTime() + 50 * 365 * 86400 * 1000).toLocaleString('sv').split(" ")[0]}
        />
        {fieldName === "dateofbirth" && utilityFunctions.checkIfNotValidDateOfBirth(localValue) && (
          <span className={"not-valid-form"}>Please select a valid age to proceed with registration</span>
        )}
        {fieldName === "idexpiration" && utilityFunctions.checkIfExpDateIsNotValid(localValue) && (
          <span className={"not-valid-form"}>Please select a valid id expiry date to proceed with registration (at least 90 days)</span>
        )}
        {fieldName === "idexpiration" && utilityFunctions.checkIfDateIsMore50(localValue) && (
          <span className={"not-valid-form"}>Please select a valid date (not more than 50 years)</span>
        )}
      </div>
    );
  };

  const renderImageField = (fieldName) => {
    const isPDF = localValue ? localValue.split("/")[1].split(";")[0] === "pdf" : false;
    const openBase64NewTab = (base64Pdf) => {
      const base64ToArrayBuffer = (data) => {
        let binaryString = window.atob(data);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes;
      };
      let arrBuffer = base64ToArrayBuffer(base64Pdf);
      let blob = new Blob([arrBuffer], { type: "application/pdf" });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      }
    };
    const handleUploadClick = (e) => {
      hiddenFileInput.current.click();
    };
    const onRemoveClick = () => {
      setIsUploading(true);
      accountAPI.removeImage(accID, uploadedImageID).then((response) => {
        setIsUploading(false);
        handleChange(utilityFunctions.transformToNestedField(fieldName, true), "");
      });
    };
    const hangleShowPreview = (isExample) => {
      if (isExample) {
        setPreviewOpen(!previewOpen);
        setShowExample(true);
      } else {
        setShowExample(false);
        setPreviewOpen(!previewOpen);
      }
    };
    const renderPreview = () => {
      if (previewOpen) {
        return (
          <div className="fullpreview" onClick={() => hangleShowPreview()}>
            {!isPDF ? (
              <img
                className="image"
                src={
                  showExample
                    ? `${process.env.PUBLIC_URL}/${fieldName}.png`
                    : localValue
                }
                alt=" "
              />
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href="#"
                className="new-tab-pdf-link"
                onClick={() => {
                  openBase64NewTab(
                    localValue.split(",")[1],
                    localValue.split(":")[1].split(";")[0]
                  );
                  return false;
                }}
              >
                click to open in a new tab
              </a>
            )}
          </div>
        );
      }
      return null;
    };
    const renderExample = () => {
      if (!localValue) {
        return (
          <div className="review-example-image">
            <img
              className="example-image"
              onClick={() => hangleShowPreview(true)}
              src={`${process.env.PUBLIC_URL}/${fieldName}.png`}
              alt="example"
            />
            {!!imageInfo && (
              <span className="info-error-text">{imageInfo}</span>
            )}
          </div>
        );
      }
      return (
        <div className="review-example-image">
          <img
            className={`example-image ${isUploading ? "uploading-image" : undefined
              }`}
            onClick={() => hangleShowPreview()}
            src={isPDF ? `${process.env.PUBLIC_URL}/pdf-icon.png` : localValue}
            alt="example"
          />
          {isUploading && (
            <div className="example-spinner-wrapper">
              <Spinner className="spinner" animation="grow" />
            </div>
          )}
          {!isUploading && (
            <button className="remove-image-button" onClick={onRemoveClick}>
              <img src={`${process.env.PUBLIC_URL}/trash.png`} alt="remove" />
            </button>
          )}
        </div>
      );
    };
    const handleOnChange = (kind) => {
      return (event) => {
        const file = event.target.files[0];
        if (file === undefined) return
        if (file.size > MAX_IMAGE_SIZE) {
          setImageInfo(
            `File ${file.name} size ${file.size} exceeds acceptable size 5Mb`
          );
          return;
        }
        const ext = file.name.split(/\./).splice(-1)[0].toLowerCase();
        if (!ALLOWED_IMAGE_EXT.includes(ext)) {
          setImageInfo(
            `File ${file.name
            } extension ${ext} is not from allowed extension list ${JSON.stringify(
              ALLOWED_IMAGE_EXT
            )}`
          );
          return;
        }
        setImageInfo("");
        event.target.value = "";
        const imageData = { kind };
        var reader = new FileReader();
        reader.onload = (event) => {
          imageData.image = file;
          handleChange(utilityFunctions.transformToNestedField(kind, true), event.target.result);
          setIsUploading(true);
          accountAPI.uploadImage(accID, imageData).then((img) => {
            setIsUploading(false);
            setUploadedImageID(img.data.scanID);
          });
        };
        reader.readAsDataURL(file);
      };
    };
    return (
      <div className="review-upload-image">
        <label>{utilityFunctions.changeLabelView(fieldName)}</label>
        <span>{utilityFunctions.renderInfoForExampleImage(fieldName)}</span>
        <button onClick={handleUploadClick}>
          <img src={`${process.env.PUBLIC_URL}/cloud-computing.png`} alt="cloud" />
        </button>
        <input
          ref={hiddenFileInput}
          accept="image/png, image/jpeg, image/jpg,.pdf"
          type="file"
          style={{ display: "none" }}
          onClick={(e) => { e.target.value = "" }}
          onChange={handleOnChange(fieldName, fieldName)}
        />
        {renderExample()}
        {renderPreview()}
      </div>
    );
  };

  const renderEnum = (fieldName) => {
    const valuesToMap = () => {
      if (fieldName === "title") return constants.TitleList
      if (fieldName === "idtype") return constants.IDTypeList;
      return countries
    }
    const onDefaultChange = (e) => {
      const { name, value } = e.target;
      handleChange(utilityFunctions.transformToNestedField(name), value);
    };
    const renderOptions = () => {
      return valuesToMap()?.map((item, index) => {
        if (typeof item === "string") {
          return (
            <option value={item} key={index}>
              {item}
            </option>
          );
        } else {
          if (item.allowtrade)
            return (
              <option value={item.name} key={index}>
                {item.name}
              </option>
            )
        }
        return null
      }).filter(n => n);
    };

    return (
      <div className="review-default-field-item">
        <label>{utilityFunctions.changeLabelView(fieldName)}</label>
        <select value={localValue} name={item.field} onChange={onDefaultChange}>
          {renderOptions()}
        </select>
      </div>
    );
  };

  const renderBooleanField = (fieldName) => {
    const onChange = (e) => {
      const { name, value } = e.target;
      handleChange(utilityFunctions.transformToNestedField(name), {
        pep: !!value.length,
        details: value,
      });
    };
    return (
      <div className="review-default-field-item">
        <label>{utilityFunctions.changeLabelView(fieldName)}</label>
        <div>
          <input
            onChange={onChange}
            value={localValue?.details}
            name={fieldName}
            type="text"
          />
        </div>
      </div>
    );
  };

  const renderAccordingField = (fieldName) => {
    switch (fieldName) {
      case "title":
      case "idtype":
      case "citizenship":
      case "country":
        return renderEnum(fieldName);
      case "pep":
        return renderBooleanField(fieldName);
      case "firstName":
      case "middleName":
      case "lastName":
      case "phone":
      case "street":
      case "city":
      case "postalCode":
      case "idnumber":
        return renderDefaultField(fieldName);
      case "dateofbirth":
      case "idexpiration":
        return renderDateField(fieldName);
      case "scans":
      case "identity":
      case "photoIDback":
      case "photoID":
      case "address":
        return renderImageField(fieldName);

      default:
        return renderDefaultField(fieldName);
    }
  };

  const renderCommentFromSupport = () => {
    return (
      <div className="item-comment-block">
        {item.comment.split("\n").map((line, index) => (
          <div className="item-comment" key={index}>{line}</div>))}
      </div>
    )
  }

  return (
    <div
      className={`review-field-item-block ${isTouched ? "done-bg" : undefined
        }`}
    >
      {renderCommentFromSupport()}
      {renderAccordingField(utilityFunctions.transfromFromNestedField(item.field))}
    </div>
  );
};
