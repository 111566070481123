import momentTZ from "moment-timezone";
import moment from "moment";
import constants from "lib/constants";

const TODAY = "Today";
const YESTERDAY = "Yesterday";

export const supportTableUtils = {
  tableStatusTimeFormat: (date) => {
    const formattedDate = momentTZ(date).tz("America/New_York") || momentTZ().tz("America/New_York")

    return `${formattedDate.format("MMMM Do, YYYY")} at  ${formattedDate.format(
      "h:mm A"
    )}`;
  },
  tableTimeFormat: (date, noTime) => {
    if (!date) return constants.actionData.PENDING;

    let result = momentTZ(date).tz("America/New_York");
    let dayDiff = moment()
      .startOf("day")
      .diff(momentTZ(date).tz("America/New_York").startOf("day"), "days");

    switch (dayDiff) {
      case 0:
        if (noTime) {
          result = TODAY;
        } else {
          result = `${TODAY} at ${result.format("h:mm A")}`;
        }
        break;
      case 1:
        if (noTime) {
          result = YESTERDAY;
        } else {
          result = `${YESTERDAY} at ${result.format("h:mm A")}`;
        }
        break;
      default:
        if (noTime) {
          result = result.format("YYYY-MM-DD");
        } else {
          result = result.format("YYYY-MM-DD h:mm A");
        }
        break;
    }

    return result;
  },
};
