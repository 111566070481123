import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { statisticAPI } from 'api/stats-api';
import { statementsThunks } from "actions-thunks/statements-thunk";
import { Spinner } from "react-bootstrap";
import StatementsStateController from "./state-controller/StatementsStateController";
import useStatistic from "ui/hooks/useStatistic";

import "./statements.scss";

const Statements = () => {
  const [statementsRequested, setStatementsRequested] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.utils);
  const { statements } = useSelector((state) => state.statements);
  const { currentAccount } = useSelector((state) => state.accounts);
  useStatistic(currentAccount?._id, "Open page Monthly Statements");

  useEffect(() => {
    if (statements === null && currentAccount?._id && !statementsRequested) {
      dispatch(statementsThunks.getStatementsListTC(currentAccount._id));
      setStatementsRequested(true);
    }
  }, [statements, currentAccount, statementsRequested, dispatch]);

  const onDownloadClick = (file) => {
    dispatch(statementsThunks.getStatementFileTC(currentAccount?._id, file));
    statisticAPI.sendStatUsage(currentAccount._id, { page: 'Download a monthly statement' });
  };

  const renderedStatements = () => {
    if (statements?.length) {
      const statementsToRender = statements.map((s) => {
        const daysDifference = moment().diff(s.updatedAt, "days");
        let formattedDate = moment(s.updatedAt).format("YYYY-MM-DD H:mm A");

        if (daysDifference === 0) {
          formattedDate = `Today at ${moment(s.updatedAt).format("H:mm A")}`;
        } else if (daysDifference === 1) {
          formattedDate = `Yesterday at ${moment(s.updatedAt).format(
            "H:mm A"
          )}`;
        }

        return (
          <div
            className="statements-list-item"
            key={s.filename}
            onMouseEnter={() => setHoveredItemId(s._id)}
            onMouseLeave={() => setHoveredItemId(null)}
          >
            <div className="list-item-left">
              {moment(s.period).format("MMMM YYYY")}
            </div>
            <div className="list-item-right">
              <span>{formattedDate}</span>
              <img
                onClick={() => onDownloadClick(s)}
                src={`${process.env.PUBLIC_URL}/download${hoveredItemId === s._id ? "-gr" : ""
                  }.svg`}
                alt="download"
              />
            </div>
          </div>
        );
      });

      return (
        <StatementsStateController items={statements}>
          {statementsToRender}
        </StatementsStateController>
      );
    } else if (statements !== null) {
      return (
        <div className="no-statements-wrapper">
          <img
            src={`${process.env.PUBLIC_URL}/no-statements.svg`}
            alt="statement-icon"
          />
          <span className="no-statements-message">
            No monthly statements available
          </span>
        </div>
      );
    } else if (loading) {
      return <Spinner className="spinner" animation="grow" />;
    } else return null;
  };

  return (
    <div className="statements-wrapper">
      <div className="statements-list-block">
        <div className="statements-list-wrapper">{renderedStatements()}</div>
      </div>
    </div>
  );
};

export default Statements;
