import { SET_STATEMENTS } from "./types";

const initialState = {
  statements: null,
};

const statementsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STATEMENTS:
      return {...state, statements: payload}
    default:
      return state;
  }
};

export default statementsReducer;
