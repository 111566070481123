
import { number, addMethod } from 'yup';

export const isRegularCategory = (category) => {
  return category.name !== 'max_loss' && category.name !== 're_enable';
}

export const createFormFieldValidation = (name) => {
  addMethod(number, name, function (activeRiskSetting) {
    return this.test(`isValid${name}`, function (value) {
      const { path, createError } = this;
      if (value === activeRiskSetting.settings?.default) {
        return createError({ path, message: "New value should differ from the current value" });
      }

      if (isRegularCategory(activeRiskSetting) && value > 100000000) {
        return createError({ path, message: "Maximum allowed value: 100,000,000" });
      }

      if (!isRegularCategory(activeRiskSetting) && value > 10000000) {
        return createError({ path, message: "Maximum allowed value: 10,000,000" });
      }

      return true;
    });
  });
}