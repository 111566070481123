import { Modal, Button } from "@lynx/ui-components";
import cn from "classnames";

import "./styles.scss";

const ConfirmationModal = ({
  isApplyButtonRed,
  closeModal,
  showEditModal,
  onApplyClick,
  onCancelClick,
  item,
  labels,
}) => {
  const { title, body, buttons } = labels;

  const handleSubmitModal = () => {
    closeModal();
    onApplyClick?.(item);
  };

  const handleCancelModal = () => {
    closeModal();
    onCancelClick?.(item);
  };

  return (
    <div className="modal-window">
      <Modal
        className="confirm-modal cabinet-modals"
        styleType="zimtra-primary"
        isOpen={showEditModal}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={() => closeModal()}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={() => closeModal()}
          title={<h2 className="zimtra-h2">{title}</h2>}
        />
        <Modal.Body className="modal-inner-body">{body}</Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-center">
          <Button
            size="md"
            buttonClassName={cn({
              "cancel-button": isApplyButtonRed,
            })}
            onClick={handleSubmitModal}
            label={buttons?.apply || "Yes"}
          />
          {buttons?.decline && <Button
            size="md"
            buttonClassName="cabinet-secondary-btn"
            buttonStyle="secondary"
            onClick={handleCancelModal}
            label={buttons?.decline || "No"}
          />}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
