import { useState, useEffect } from "react";
import cn from "classnames";
import "./search.scss";

const AttachmentSearch = ({ src }) => {
  const [tableData, setTableData] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [preparedData, setPreparedData] = useState(null);
  const [elementsInDropbox, setElementsInDropbox] = useState(null);
  const [elementsFound, setElementsFound] = useState(0);
  const [elementIsFound, setElementIsFound] = useState(false);

  // Process the 'src' prop and set it as 'tableData' when the component mounts
  useEffect(() => {
    if (src) {
      setTableData(src);
    }
  }, [src]);

  useEffect(() => {
    if (tableData) {
      let result = [];
  
      if (typeof tableData === "string") {
        result = tableData.split("\n").map(item => item.trim()).filter(item => item);
      }

      setPreparedData(result);
    }
  }, [tableData]);
  

  useEffect(() => {
    const check = typeof preparedData === "object" && preparedData?.filter((name) =>
      name.includes(inputValue?.toUpperCase())
    );
    if (check?.length && inputValue?.length) {
      setElementsFound(check.length);
      setElementsInDropbox(
        check
          .sort(
            (a, b) =>
              a.indexOf(inputValue?.toUpperCase()) -
              b.indexOf(inputValue?.toUpperCase())
          )
          .slice(0, 5)
      );
      setElementIsFound(false);
    } else {
      setElementsFound(0);
      setElementsInDropbox(null);
      setElementIsFound(false);
    }
    if (typeof check === "object" && check?.some((name) => name === inputValue.toUpperCase())) {
      setElementIsFound(true);
    }
  }, [inputValue, preparedData]);

  const dataInDropbox = () => {
    const onDropboxItemClick = (item) => {
      setInputValue(item);
      navigator.clipboard.writeText(item);
    };
    return elementsInDropbox?.map((item, index) => {
      const cn =
        item.toUpperCase() === inputValue?.toUpperCase() ? "exact-match" : null;
      return (
        <span
          className={cn}
          key={index}
          onClick={() => onDropboxItemClick(item)}
        >
          {item}
        </span>
      );
    });
  };


  return (
    <div className="search-block">
      <div className="search-upper">
        <input
          className={cn({
            "exact-match": elementIsFound,
            "chosen-exact-match": elementsFound === 1
          })}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        {elementIsFound
          ? <img src={`${process.env.PUBLIC_URL}/icons/check.svg`} alt="check" />
          : <img src={`${process.env.PUBLIC_URL}/icons/question-icon.svg`} alt="question" />}
        {preparedData && (
          <div>{`${elementsFound} / ${preparedData.length}`}</div>
        )}
      </div>
      {elementsFound > 0 &&
        inputValue &&
        (elementsFound > 1 || !elementIsFound) && (
          <div className="search-lower">{dataInDropbox()}</div>
        )}
    </div>
  );
};

export default AttachmentSearch;
