import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "@lynx/ui-components";

import { statisticAPI } from 'api/stats-api';
import constants from "lib/constants";
import { requestsThunks } from "actions-thunks/requests-thunks";
import { accountThunks } from "actions-thunks/account-thunks";
import { ModalContext } from "ui/ui-kit/modal/ModalContext";
import CreateNewRequestModal from '../modals/CreateNewRequestModal';
import CreateRiskSettingsModal from '../modals/CreateRiskSettingsModal';
import CreateCustomRequestModal from '../modals/CreateCustomRequestModal';
import CreateToggleAccountModal from '../modals/CreateToggleAccountModal';

const { categoryData } = constants;

const NewRequest = ({ isActiveRequestsTable, renderFullListAfterRequest = true }) => {
  const { currentAccount } = useSelector((state) => state.accounts);
  const [activeCategory, setActiveCategory] = useState(null);
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);
  const categories = [
    { value: categoryData.RISKSETTING, label: categoryData.RISKSETTING },
    {
      ...currentAccount.status !== 'AccountEnabled' ? {
        value: 'enableAccount', label: 'Enable Account'
      } : {
        value: 'disableAccount', label: 'Disable Account'
      }
    },
    { value: categoryData.CUSTOM, label: categoryData.CUSTOM }
  ];

  const handleUpdateCategory = (selectedCategory) => {
    const newCategory = categories.find(category => category.value === selectedCategory);
    setActiveCategory(newCategory);
  }

  const handleCloseModal = () => {
    setActiveCategory(null)
    hideModal();
  };

  const sendStatisticRequest = ({ body, type }) => {
    let requestBody = ''
    if (type === 'RISKSETTING') {
      requestBody = `Create request: Risk Settings: ${body.label}`;
    }
    else if (type === 'INQUIRY') {
      requestBody = `Create request: Custom`;
    }
    else {
      requestBody = `Create request: Account Maintenance: Enable ${body.ext.action} Account`;
    }
    statisticAPI.sendStatUsage(currentAccount._id, { page: requestBody })
  }

  const handleSubmitModal = async ({ body, type, isCustomForm = false }) => {
    const { label, ...rest } = body
    let attachmentsIDs = null;
    let requestBody = rest;

    sendStatisticRequest({ body, type, isCustomForm })

    if (isCustomForm) {
      attachmentsIDs = await dispatch(accountThunks.setAccountAttachmentsTC(currentAccount._id, rest))

      if (attachmentsIDs.status === 200) {
        requestBody = {
          ...rest,
          ext: {
            ...rest.ext,
            attachments: attachmentsIDs.data.attachments.map(item => item.id)
          }
        }
      }
    }

    dispatch(requestsThunks.createDataRequestTC(currentAccount._id, requestBody, type));
    dispatch(requestsThunks.getRiskSettingsMetadataTC(currentAccount._id));
    const requestData = {
      accID: currentAccount._id,
    };
    if (!renderFullListAfterRequest) {
      requestData.statusCode = "unresolved";
    }
    // dispatch(requestsThunks.getAccountRequestsTC(requestData));

    handleCloseModal();
  }

  const handleNewRequestClick = () => {
    showModal(
      <CreateNewRequestModal
        categories={categories}
        updateCategory={handleUpdateCategory}
        closeModal={handleCloseModal}
      />)
  }

  const commonModalsProps = {
    submitModal: handleSubmitModal,
    closeModal: handleCloseModal,
    updateCategory: handleUpdateCategory,
    activeCategory,
    categories
  }

  useEffect(() => {
    if (activeCategory) {
      switch (activeCategory.value) {
        case categoryData.RISKSETTING:
          showModal(
            <CreateRiskSettingsModal {...commonModalsProps} />)
          break;
        case 'disableAccount':
        case 'enableAccount':
          showModal(
            <CreateToggleAccountModal
              {...commonModalsProps}
              accountRequestType={activeCategory.value === 'enableAccount' ? 'enable' : 'disable'}
            />)
          break;
        case categoryData.CUSTOM:
          showModal(
            <CreateCustomRequestModal {...commonModalsProps} />)
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  return (
    <div className={`account-overview-table-header`}>
      {isActiveRequestsTable && <h6 className="account-title">Active Requests</h6>}
      <Button
        onClick={handleNewRequestClick}
        size="md"
        buttonStyle="button-variant-second"
        buttonClassName="cabinet-primary-btn"
        label={<><Icon name="plus" /> New Request</>}
      />
    </div>
  )
}

export { NewRequest };