import { instance, setHeader } from "./auth-api";

const docurl = "/document";

export const documentAPI = {
  fetchDocumentListFromS3: async () => {
    const response = await fetch(
      "https://zimtra-publica-data.s3.us-east-2.amazonaws.com/faq-markdown-data/documents-list.json"
    );
    return response;
  },
  fetchDocumentFromS3: async (id) => {
    const response = await fetch(
      `https://zimtra-publica-data.s3.us-east-2.amazonaws.com/faq-markdown-data/markdowns/${id}.md`
    );
    return response.text();
  },
  createDocument: async (userData) => {
    const response = await instance.post(`${docurl}`, userData, {
      headers: setHeader(),
    });
    return response;
  },
  updateDocument: async (id, userData) => {
    const response = await instance.put(`${docurl}/${id}`, userData, {
      headers: setHeader(),
    });
    return response;
  },
  deleteDocument: async (id) => {
    const response = await instance.delete(`${docurl}/${id}`, {
      headers: setHeader(),
    });
    return response;
  },
  getImage: async (src) => {
    const response = await instance.get(`${docurl}/${src}`, {
      headers: setHeader(),
      responseType: "blob", // important
    });
    return response;
  },

  // ********** attachments ********
  getAttachment: async (currentDocument, name) => {
    const response = await instance.get(
      `${docurl}/attachment/${currentDocument}/${name}`,
      {
        headers: setHeader(),
        responseType: "blob", // important
      }
    );
    return response;
  },
  getAttachmentList: async (currentDocument) => {
    const response = await instance.get(
      `${docurl}/attachment/${currentDocument}`,
      { headers: setHeader() }
    );
    return response;
  },
  uploadAttachment: async (imageData, currentDocument) => {
    const formData = new FormData();
    formData.append("filename", imageData.file.name);
    const blob = new Blob([
      imageData.image.substr(imageData.image.indexOf(",") + 1),
    ]);
    formData.append("attachment", blob, imageData.file.name);
    const options = {
      headers: {
        ...setHeader(),
        "content-type": "multipart/form-data",
      },
    };
    const response = await instance.put(
      `${docurl}/attachment/${currentDocument}/${imageData.file.name}`,
      formData,
      options
    );
    return response;
  },
  renameAttachment: async (currentDocument, name, newName) => {
    const response = await instance.put(
      `${docurl}/attachment/${currentDocument}/${name}/rename`,
      { name: newName },
      { headers: setHeader() }
    );
    return response;
  },
  removeAttachment: async (currentDocument, name) => {
    const response = await instance.delete(
      `${docurl}/attachment/${currentDocument}/${name}`,
      { headers: setHeader() }
    );
    return response;
  },

  // ********** permalink ********
  getPermalink: async (linkCode) => {
    const response = await instance.get(`l/a/${linkCode}`);
    return response;
  },

  setAccessRule: async (documentId, userId, groupId, accessRule) => {
    let accessParams = {
      access: accessRule,
    };
    if (userId) {
      accessParams.user = userId;
    } else {
      accessParams.group = groupId;
    }
    return await instance.post(`${docurl}/share/${documentId}`, accessParams, {
      headers: setHeader(),
    });
  },

  removeAccessRule: async (documentId, userId, groupId) => {
    let accessParams = {};
    if (userId) {
      accessParams.user = userId;
    } else {
      accessParams.group = groupId;
    }
    return await instance.delete(`${docurl}/share/${documentId}`, {
      data: accessParams,
      headers: setHeader(),
    });
  },

  getAccessRules: async (documentId) =>
    await instance.get(`${docurl}/share/${documentId}`, {
      headers: setHeader(),
    }),

  getUsers: async () =>
    await instance.get(`${docurl}/share/users`, { headers: setHeader() }),
  getGroups: async () =>
    await instance.get(`${docurl}/share/groups`, { headers: setHeader() }),
};
