import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import authReducer from "./authReducer";
import documentsReducer from "./documentsReducer";
import errorReducer from "./errorReducer";
import profileReducer from "./profileReducer";
import statementsReducer from "./statementsReducer";
import requestsReducer from "./requestsReducer";
import utilsReducer from "./utilsReducer";
import marketdataReducer from "./marketdataReducer";
import chatReducer from "./chatReducer";
import socketsReducer from "./socketsReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  documents: documentsReducer,
  accounts: accountReducer,
  statements: statementsReducer,
  requests: requestsReducer,
  chat: chatReducer,
  utils: utilsReducer,
  marketdata: marketdataReducer,
  sockets: socketsReducer
});
