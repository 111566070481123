import { instance, setHeader } from "./auth-api";

export const requestsAPI = {
  getRequests: async (accID, type, statusCode) => {
    const response = await instance.get(`/request/list`, {
      headers: setHeader(),
      params: { type, gettype: 1, statusCode, applicationID: accID },
    });
    return response;
  },
  getRSMetadata: async (accID) => {
    const response = await instance.get(`request/risk-setting-metadata`, {
      headers: setHeader(),
      params: { applicationID: accID },
    });
    return response;
  },
  updateRequests: async (id, body) => {
    const response = await instance.put(
      `/request/${id}`,
      body,
      { headers: setHeader() }
    );
    return response;
  },
  createDataRequest: async (accID, body) => {
    const updatedBody = {...body, applicationID: accID}
    const response = await instance.post(
      "/request",
      updatedBody,
      { headers: setHeader() }
    );
    return response;
  },
  setRequestStatusAction: async (requestID, body = {}, action = "") => {
    const response = await instance.put(
      `/request/${requestID}/${action}`,
      body,
      { headers: setHeader() }
    );
    return response;
  },
};
