import { Button } from "@lynx/ui-components";
import cn from "classnames";

import { setBundleColor } from "../helpers";

const Bundles = ({
  bundle,
  primaryBundle,
  isBundlesDisabled,
  handleRequestMarketBundleChange,
  changingStatus,
  buttonItem,
  requestedMarketsData,
  filterMarketsToTradingPlatform,
}) => {
  const onBundleButtonClick = ({ name, disabled }) => {
    if (name === primaryBundle || disabled) return;
    handleRequestMarketBundleChange({
      name,
    });
  };
  const isButtonDisabled = (buttonName, isBundlesDisabled, filterMarketsToTradingPlatform) => {
    if (isBundlesDisabled) {
      return true;
    }
  
    const shouldDisable = !filterMarketsToTradingPlatform.some((m, i, array) => {
      if (array.length <= 1 && buttonName !== "Basic") {
        return false;
      }
      return m.bundle.includes(buttonName);
    });
  
    return shouldDisable;
  };

  return (
    <div className="marketdata-bundle">
      <span className="bundle-text">Bundle:</span>
      <div
        className={cn("marketdata-button-group", {
          disabled: isBundlesDisabled,
        })}
      >
        {bundle.map((button) => (
          <Button
            key={`radio-${button.name}`}
            size="md"
            name={button.name}
            buttonClassName={cn(
              setBundleColor({
                name: button.name,
                changingStatus,
                buttonItem,
                primaryBundle,
                requestedMarketsData,
              })
            )}
            buttonStyle="zimtra-secondary"
            onClick={() => onBundleButtonClick(button)}
            disabled={isButtonDisabled(button.name, isBundlesDisabled, filterMarketsToTradingPlatform)}
            label={button.name}
          />
        ))}
      </div>
    </div>
  );
};

export default Bundles;
