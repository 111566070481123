import { useEffect, useState } from "react";
import cn from "classnames";
import { Spinner } from "react-bootstrap";
import AttachmentSearch from "ui/ui-kit/search/AttachmentSearch";
import AttachmentDownload from "ui/ui-kit/attachment/AttachmentDownload";

import styles from "./styles.module.scss";

const Resources = () => {
  const [files, setFiles] = useState([]);
  const [freshestFileContent, setFreshestFileContent] = useState(null);

  useEffect(() => {
    const generateFileNames = () => {
      const today = new Date();
      const fileNames = [];

      // Try to generate file names for the last 10 days
      for (let i = 0; i < 10; i++) {
        const date = new Date();
        date.setDate(today.getDate() - i);
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        const fileName = `etb/ETB_${year}${month}${day}.csv`;
        fileNames.push(fileName);
      }

      return fileNames;
    };

    const checkIfFileExists = async (fileName) => {
      const fileUrl = `https://zimtra-publica-data.s3.us-east-2.amazonaws.com/${fileName}`;
      try {
        const response = await fetch(fileUrl, { method: 'HEAD' });
        return response.ok;
      } catch (error) {
        console.error(`Error checking file existence: ${fileName}`, error);
        return false;
      }
    };

    const fetchFiles = async () => {
      try {
        const potentialFileNames = generateFileNames();
        const existingFiles = [];

        // Check which files actually exist
        for (const fileName of potentialFileNames) {
          const exists = await checkIfFileExists(fileName);
          if (exists) {
            existingFiles.push(fileName);
          }
        }

        if (existingFiles.length === 0) {
          console.error("No ETB files found.");
          return;
        }

        setFiles(existingFiles);

        // Fetch and display the freshest file content
        const freshestFileUrl = `https://zimtra-publica-data.s3.us-east-2.amazonaws.com/${existingFiles[0]}`;
        const freshestResponse = await fetch(freshestFileUrl);
        if (freshestResponse.ok) {
          const text = await freshestResponse.text();
          setFreshestFileContent(text);
        } else {
          console.error(`Failed to fetch the freshest file: ${existingFiles[0]}`);
        }
      } catch (error) {
        console.error("Error fetching file content:", error);
      }
    };

    fetchFiles();
  }, []);

  const helperDownloadFile = (text, label) => {
    // Create a Blob from the text
    const blob = new Blob([text], { type: "text/csv;charset=utf-8;" });

    // Create a link element, set its download attribute, and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", label || "download.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadClick = async (fileName) => {
    const fileUrl = `https://zimtra-publica-data.s3.us-east-2.amazonaws.com/${fileName}`;
    try {
      const response = await fetch(fileUrl);
      if (response.ok) {
        const text = await response.text();
        helperDownloadFile(text, fileName);
      } else {
        console.error(`Failed to fetch file: ${fileName}`);
      }
    } catch (error) {
      console.error(`Failed to fetch file: ${fileName}`, error);
    }
  };

  return (
    <div className={cn("page-wrapper", styles.resources)}>
      <h3>Easy to Borrow</h3>
      {files.length > 0 ? (
        <div>
          <strong>Today's ETB list.</strong>&nbsp;
          <span>Start typing symbol name to see exact or partial match.</span>
          {freshestFileContent && (
            <AttachmentSearch src={freshestFileContent} />
          )}
          <br />
          <div className={styles.body}>
            <span>DOWNLOAD FILES</span>
            <strong>Current</strong>
            <AttachmentDownload
              label={files[0]}
              onDownloadClick={() => handleDownloadClick(files[0])}
            />
            <br />
            {files.length > 1 && <strong>Recent</strong>}
            {files.slice(1).map((file) => (
              <AttachmentDownload
                key={file}
                label={file}
                onDownloadClick={() => handleDownloadClick(file)}
              />
            ))}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Resources;
