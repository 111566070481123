// @ts-nocheck
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import cn from "classnames";

import { documentThunks } from "actions-thunks/documents-thunks";
import ToastComponent from "../ui-kit/toast/Toast";
import Popup from "../ui-kit/popup/Popup";
import { authThunks } from "actions-thunks/auth-thunks";
import SidebarNavigation from "ui/sidebar-navigation/SiIdebarNavigation";
import { navigationList } from "ui/sidebar-navigation/helpers";
import MainTaskbar from "ui/components/taskbar/main-taskbar";
import { setSidebarBlockStatusAC } from "actions-thunks/actions";

import "./default-layout.scss";
import { setCurrentPageTitleAC } from "actions-thunks/actions";

const DefaultLayout = ({
  component: Component,
  setFieldIsEditing,
  fieldIsEditing,
  setPopupState,
  pickedDocument,
  sidebarBlockCollapsed,
  portalWorkflowType,
  popupState,
  redirectItem,
  listOfDocuments,
}) => {
  const [currentAttachmentClicked, setCurrentAttachmentClicked] =
    useState(null);
  const [triggerSubmitData, setTriggerSubmitData] = useState(false);
  const [wireSent, setWireSent] = useState(null);
  const [wireSentClicked, setWireSentClicked] = useState(false);
  const [activeSidebarItem, setActiveSidebarItem] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const found = navigationList.find(
      (i) =>
        `/${i.destination.split("/")[1]}` ===
        `/${location?.pathname.split("/")[1]}`
    );
    if (found) {
      setActiveSidebarItem(found);
      //set header title
      dispatch(setCurrentPageTitleAC(found.summary));
    }
  }, [location, dispatch]);

  //handle popup button click
  const onPopupClickHandler = (type) => {
    const fnToCall = () => {
      switch (redirectItem.type) {
        case "upper":
          return onSideItemClick(redirectItem.item, true);
        case "logout":
          return dispatch(authThunks.logoutTC());
        default:
          break;
      }
    };
    switch (type) {
      case "account-wire":
        setWireSent(true);
        setPopupState({ opened: false, message: "", type: "" });
        break;
      case "remove-attachment":
        dispatch(
          documentThunks.removeAttachmentTC(
            pickedDocument?.id,
            currentAttachmentClicked
          )
        );
        setPopupState({ opened: false, message: "", type: "" });
        break;
      case "save":
        setFieldIsEditing({ status: false, type: "" });
        setTriggerSubmitData(true);
        fnToCall();
        setPopupState({ opened: false, message: "", type: "" });
        break;
      case "notsave":
        setFieldIsEditing({ status: false, type: "" });
        fnToCall();
        setPopupState({ opened: false, message: "", type: "" });
        break;
      case "stay":
        setPopupState({ opened: false, message: "", type: "" });
        break;
      default:
        break;
    }
  };

  const onCollapseClick = (e) => {
    e?.preventDefault();
    dispatch(setSidebarBlockStatusAC(!sidebarBlockCollapsed));
  };

  const onSideItemClick = (item) => {
    if (item) {
      setActiveSidebarItem(item);
      const isDesktop = window.innerWidth >= 980;
      if (!isDesktop) {
        onCollapseClick();
      }
    }
  };

  return (
    <div
      className={cn("default-layout-block", {
        "expanded-sidebar": !sidebarBlockCollapsed,
      })}
    >
      <SidebarNavigation
        portalWorkflowType={portalWorkflowType}
        onSideItemClick={onSideItemClick}
        onCollapseClick={onCollapseClick}
        activeSidebarItem={activeSidebarItem}
        sidebarBlockCollapsed={sidebarBlockCollapsed}
      />
      <div className="main-body-block" id="main-body-block">
        <MainTaskbar />
        <Component
          setFieldIsEditing={setFieldIsEditing}
          portalWorkflowType={portalWorkflowType}
          fieldIsEditing={fieldIsEditing}
          wireSent={wireSent}
          listOfDocuments={listOfDocuments}
          onSideItemClick={onSideItemClick}
          wireSentClicked={wireSentClicked}
          setWireSentClicked={setWireSentClicked}
          setWireSent={setWireSent}
          triggerSubmitData={triggerSubmitData}
          setTriggerSubmitData={setTriggerSubmitData}
          setPopupState={setPopupState}
          setCurrentAttachmentClicked={setCurrentAttachmentClicked}
        />
      </div>
      <Popup
        popupState={popupState}
        fieldIsEditing={fieldIsEditing}
        onPopupClickHandler={onPopupClickHandler}
      />
      <ToastComponent />
    </div>
  );
};

export default DefaultLayout;
