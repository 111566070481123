import { instance, setHeader } from "./auth-api";

export const chatAPI = {
  getChatMessages: async (accID, requestID) => {
    const response = await instance.get(`/chat/${accID}/${requestID}`, {
      headers: setHeader(),
    });
    return response;
  },
  // sendChatMessages: async (accID, requestID, body) => {
  //   const response = await instance.post(`/account/${accID}/request/${requestID}/chat/send`, body, {
  //     headers: setHeader(),
  //   });
  //   return response;
  // },
};
