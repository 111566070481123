import {
  SET_IS_AUTHENTICATED,
  SET_CURRENT_USER,
  SET_RECEIVED_MESSAGE,
} from "./types";

const initialState = {
  isAuthenticated: false,
  currentUser: {},
  receivedMessage: "",
  redirectItem: null,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      };
    case SET_RECEIVED_MESSAGE:
      return {
        ...state,
        receivedMessage: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
