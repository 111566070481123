import { instance, setHeader } from "./auth-api";

const accounturl = "/application";

export const statementsAPI = {
  getStatements: async (accID) => {
    const response = await instance.get(
      `${accounturl}/statement/list`,
      {
        headers: setHeader(),
        id: accID
      }
    );
    return response;
  },
  getStatementFile: async (accID, fileID) => {
    const response = await instance.get(
      `${accounturl}/${accID}/statement/${fileID}`,
      {
        headers: setHeader(),
        responseType: "blob", // important
      }
    );
    return response;
  },
};
