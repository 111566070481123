import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWireImageAC } from "../../actions-thunks/actions";
import { documentThunks } from "../../actions-thunks/documents-thunks";

import { utilityFunctions } from "../utilities/utilityFunctions";

import "./EditProfile.scss";

const UploadButton = ({
  showTrash = false,
  show = true,
  disabledBtn = false,
  setNewImageUploaded,
  setPopupState,
  actionType,
}) => {
  const { currentDocument } = useSelector((state) => state.documents);
  const [uploadedFileName, setUploadedFileName] = useState(showTrash);

  const trashImage = `${process.env.PUBLIC_URL}/backet-icon.svg`;

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const maxSizeImage = 5242880;
  const maxSizeFile = 52428800;

  const handleUpload = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    setUploadedFileName(showTrash);
  }, [showTrash]);

  const handleDisplayFileDetails = (e) => {
    const backAnswer = (max) => {
      setPopupState({
        opened: true,
        message: max
          ? `Your file exceeded max size of 5 mb`
          : "Wrong file type",
        type: "information",
      });
    };
    const fileType = inputRef?.current?.files[0]?.type.split("/");
    if (inputRef.current?.files?.length) {
      if (
        actionType === "attachment" &&
        !utilityFunctions.extensionsTypes.includes(fileType[1].toUpperCase())
      ) {
        return backAnswer();
      }
      if (
        actionType === "fee" &&
        inputRef.current.files[0]?.size > maxSizeImage
      ) {
        return backAnswer(true);
      }
      if (inputRef.current.files[0]?.size > maxSizeFile) {
        return backAnswer(true);
      }
      setUploadedFileName(inputRef.current.files[0]?.name);
      let reader = new FileReader();
      const imageData = {
        file: e.target.files[0],
        kind: actionType,
      };
      reader.onload = (event) => {
        imageData.image = event.target.result;
        if (actionType === "attachment") {
          dispatch(
            documentThunks.uploadAttachmentTC(imageData, currentDocument.id)
          );
        }
        if (actionType === "fee") {
          dispatch(setWireImageAC(imageData));
          setNewImageUploaded(true);
        }
      };
      reader.readAsDataURL(inputRef.current?.files[0]);
    }
  };

  const onTrashClick = () => {
    setPopupState({
      opened: true,
      message: `Are you sure you want to remove your avatar`,
      type: "remove-attachment",
    });
  };

  if (actionType !== "attachment")
    return (
      <div className="upload-button-block">
        <input
          ref={inputRef}
          onChange={handleDisplayFileDetails}
          onClick={(e) => {
            e.target.value = "";
          }}
          accept={
            actionType === "fee"
              ? "image/png, image/jpeg, image/jpg,.pdf"
              : undefined
          }
          className="d-none"
          type="file"
        />
        <button
          id="main-upload-image-button"
          onClick={handleUpload}
          disabled={disabledBtn}
          className={`btn default-button button-without-bg btn-outline-${
            uploadedFileName ? "success" : "primary"
          } ${show ? undefined : "d-none"}`}
        >
          {actionType === "fee"
            ? "Optionally, you may upload a wire confirmation to help us find your wire quicker. (Up to 5MB)"
            : "Upload"}
        </button>
        {showTrash && (
          <button className="trash-button" onClick={() => onTrashClick()}>
            <img alt="trash" height="24" width="24" src={trashImage} />
          </button>
        )}
        {actionType === "fee" && (
          <div
            className={`upload-plus-icon-block ${show ? undefined : "d-none"}`}
          >
            <img src={`${process.env.PUBLIC_URL}/plus-icon.svg`} alt="plus" />
          </div>
        )}
      </div>
    );
  return (
    <div className="document-upload-button-block">
      <input
        ref={inputRef}
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={handleDisplayFileDetails}
        className="d-none"
        type="file"
      />
      <button
        disabled={disabledBtn}
        onClick={handleUpload}
        className="document-upload-button"
      >
        <img src={`${process.env.PUBLIC_URL}/plus.png`} alt="plus" />
      </button>
    </div>
  );
};

export default UploadButton;
