import {
  INITIALIZE_SOCKET,
  ADD_SOCKET_NAMESPACE,
  REMOVE_SOCKET_NAMESPACE,
  ADD_SOCKET_NAMESPACE_ERROR
} from "../reducers/types";

import store from 'store';
import {
  setChatMessageAC,
  setErrorAC,
  setLoadingAC,
  updateAccRequestAC,
  updateRiskSettingsMetadataAC,
  updateRequestedMarketsAC,
  setAccountExtAC,
  updateIsInitializedSocketAC,
  updateCurrentAccountAC
} from "../actions-thunks/actions";
import {
  accountThunks
} from 'actions-thunks/account-thunks';

export default function socketMiddleware(socketClient) {
  return (params: any) => (next: any) => (action: any) => {
    const { dispatch } = params;
    const { type, payload } = action;

    switch (type) {
      case INITIALIZE_SOCKET: {
        if (socketClient.sockets.size < 1 && !socketClient.manager) {
          socketClient.initializeManager();
          dispatch(updateIsInitializedSocketAC(true))
        }
        break;
      }

      case ADD_SOCKET_NAMESPACE: {
        const { name, data } = payload;
        if (!socketClient.sockets.get(name)) {
          socketClient.addNamespace(name);
          const socketEntity = socketClient.sockets.get(name);
          if (name === 'chat') {
            dispatch(setLoadingAC(true))
            socketEntity.on("connect", () => {
              if (data && data.id) {
                socketEntity.emit("chat:attach", data.id);
              }
            });
            let messageQueue = [];
            let isProcessingQueue = false;

            const processQueue = async () => {
              if (isProcessingQueue) return;
              isProcessingQueue = true;

              while (messageQueue.length > 0) {
                const socketData = messageQueue.shift();
                if (socketData.attachments.length > 0) {
                  const accountID = store.getState().accounts.currentAccount._id;
                  const messageAttachments = socketData.attachments.map(item => dispatch(accountThunks.getAccountAttachmentTC(accountID, item, true)));
                  const attachment = await Promise.all(messageAttachments);
                  dispatch(setChatMessageAC({
                    ...socketData,
                    attachmentsData: attachment
                  }));
                } else {
                  dispatch(setChatMessageAC(socketData));
                }
              }

              isProcessingQueue = false;
            };

            socketEntity.on("chat:update", (_, socketData) => {
              if (socketData.messageType === "PUBLIC") {
                messageQueue.push(socketData);
                processQueue();
              }
              dispatch(setLoadingAC(false));
            });
          }
          else if (name === 'applications') {
            socketEntity.on("connect", () => {
              if (data && data.id) {
                socketEntity.emit("applications:attach", data.id);
              }
            });
            socketEntity.on("applications:update", (id, socketData) => { //id = requestID if chat socket; id = in any other case
              if (socketData) {
                const { request, riskSettingParam, marketData, application } = socketData;
                if (request) {
                  dispatch(updateAccRequestAC(request));
                  dispatch(updateRequestedMarketsAC(request));
                }
                if (riskSettingParam) {
                  dispatch(updateRiskSettingsMetadataAC(riskSettingParam));
                }
                if (marketData) {
                  const { extensions } = store.getState().accounts;
                  if (extensions) {
                    const updatedExtensions = extensions.map((extension) => {
                      if (extension.ext.markets) {
                        return {
                          ...extension,
                          ext: {
                            ...extension.ext,
                            markets: marketData.markets
                          }
                        };
                      }
                      return extension;
                    });
                    dispatch(setAccountExtAC(updatedExtensions));
                  }
                }
                if (application) {
                  dispatch(updateCurrentAccountAC({status: application.status}));
                }
              }
              dispatch(setLoadingAC(false))
            });
          }

          if (socketEntity) {
            socketEntity.on("exception", (message) => {
              dispatch(setErrorAC({
                type: ADD_SOCKET_NAMESPACE_ERROR,
                message,
              }))
            });
          }
        }
        break;
      }

      case REMOVE_SOCKET_NAMESPACE: {
        const socketEntity = socketClient.sockets.get(payload);

        if (socketEntity) {
          socketEntity.off("connect");

          if (payload === 'chat') {
            socketEntity.off("chat:refresh");
            socketEntity.off("chat:update");
            socketEntity.off("chatMessageReceivedEvent");
          } else if (payload === 'application') {
            socketEntity.off("application:update");
          }

          socketEntity.off("exception");
          socketClient.removeNamespace(payload);
        }
        break;
      }

      default:
        break;
    }

    return next(action)
  }
}
