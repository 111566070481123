import { useContext } from "react";
import { Icon } from '@lynx/ui-components';
import cn from "classnames";
import { Tooltip } from "react-tooltip";

import { utilityFunctions } from "ui/utilities/utilityFunctions";
import { ModalContext } from "ui/ui-kit/modal/ModalContext";
import CreateNewRequestModal from "../modals/CreateRequestFromRiskSettingsModal";
import EditIcon from "images/edit.svg";

import "./risk-setting.scss";

const renderRSIcon = (settingData, showInOverlay) => {
  if (settingData.settings?.isRequested) {
    return showInOverlay(
      <div className="rs-overlay-status">
        <div>Current status of request: Pending</div>
        <div>Request ID: {settingData.settings?.sequenceNumber}</div>
      </div>,
      settingData.name);
  }
  if (settingData.settings?.editable) {
    return <img src={EditIcon} width="20" height="20" alt="edit" />;
  }
  if (settingData.settings?.disabled) {
    return showInOverlay(
      <div className="rs-overlay-status">
        {settingData.settings.disabledMessage}
      </div>,
      settingData.name);
  }
  return <Icon name="info" />
};

const RiskSetting = ({ settingData, createRSRequest }) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const showInOverlay = (data, id) => {
    return (
      <>
        <Icon id={`anchor-tooltip-${id}`} name="info" />
        <Tooltip delayShow={250} delayHide={400} anchorSelect={`#anchor-tooltip-${id}`}>
          <div className="i-text">{data}</div>
        </Tooltip>
      </>
    );
  };

  return (
    <div className="body-item">
      <div className="hv-triangle"></div>
      <div className="font-size-body">{settingData.label}</div>
      <div
        className={cn("body-item-right", {
          editable: settingData.settings?.editable,
        })}
        onClick={() => {
          if (
            settingData.settings?.editable &&
            !settingData?.settings?.isRequested
          ) {
            showModal(
              <CreateNewRequestModal
                showEditModal={true}
                closeModal={hideModal}
                settingData={settingData}
                update={createRSRequest}
              />
            );
          }
        }}
      >
        <div>
          {utilityFunctions
            .currencyFormatter()
            .format(
              settingData.settings?.value
                ? settingData.settings?.value
                : settingData.settings?.default
            )
            .replace(/\D00$/, "")}
        </div>
        <div className={"rs-edit-icon"}>{renderRSIcon(settingData, showInOverlay)}</div>
      </div>
    </div>
  );
};

export default RiskSetting;
