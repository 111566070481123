import {
  LIST_ACCOUNTS,
  SET_CURRENT_ACCOUNT,
  SET_WIRES_LIST,
  SET_WIRE_IMAGE,
  SET_ACC_EXT,
  SET_COUNTRIES,
  SET_STATUS_HISTORY,
  UPDATE_CURRENT_ACCOUNT,
} from "./types";

const initialState = {
  accounts: null,
  currentAccount: null,
  countries: null,
  statusHistory: null,
  extensions: null,
  wireImage: null,
  wiresList: null,
};

const accountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
      };
    case SET_CURRENT_ACCOUNT:
      return {
        ...state,
        currentAccount: payload,
      };
    case UPDATE_CURRENT_ACCOUNT:
      return {
        ...state,
        currentAccount: {...state.currentAccount, ...payload},
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      };
    case SET_STATUS_HISTORY:
      return {
        ...state,
        statusHistory: payload,
      };
    case SET_ACC_EXT:
      return {
        ...state,
        extensions: payload,
      };
    case SET_WIRE_IMAGE:
      return {
        ...state,
        wireImage: payload,
      };
    case SET_WIRES_LIST:
      return {
        ...state,
        wiresList: payload,
      };
    default:
      return state;
  }
};

export default accountReducer;
