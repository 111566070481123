const TitleList = ["Mr.", "Mrs.", "Ms.", "Mx.", "Miss"];

const IDTypeList = ["passport", "driverlicense", "idcard"];

//document constants
const DocumentFormatList = ["RAW", "HTML", "MARKUP"];

const DocumentPermissionList = ["PUBLIC", "PRIVATE", "GROUP"];

const DocumentStatusList = [
  "DRAFT",
  "REVIEW",
  "REJECTED",
  "APPROVED",
  "REQUEST",
  "PUBLISHED",
  "ARCHIVED",
];

const listForNotEnabledAccount = ["accountsetup", "profile", "faq", "etb"];

const listForSimulatorAccount = ["profile", "simulator", "faq", "etb"];

const listForDocumentsAccount = ["profile", "faq", "etb"];

const listForEnabledAccount = [
  "accountoverview",
  "profile",
  "statements",
  "marketdata",
  "monthlystatements",
  "faq",
  "etb",
  "support",
];

const RequestFormStatusTypes = {
  OPEN: "OPEN",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  BLOCKED: "BLOCKED",
  ONHOLD: "ONHOLD",
  INPROGRESS: "INPROGRESS",
  DECLINED: "DECLINED",
  PENDING_EOD: "PENDING_EOD",
  WAITING_FOR_CLIENT: "WAITING_FOR_CLIENT",
  WAITING_FOR_3RD_PARTY: "WAITING_FOR_3RD_PARTY",
  NEW_MESSAGE: "NEW_MESSAGE",
  STALE: "STALE",
  START: "start",
  STOP: "stop",
  RESOLVE: "resolve",
};

const notActiveRequestsStates = [
  RequestFormStatusTypes.STALE,
  RequestFormStatusTypes.CANCELED,
  RequestFormStatusTypes.DECLINED,
  RequestFormStatusTypes.COMPLETED,
];

const categoryData = {
  RISKSETTING: "Risk Settings",
  MARKETDATA: "Market Data",
  TRADING_ACCESS: "Account Maintenance",
  CUSTOM: "Custom",
};

const contextMenuOptionType = {
  OPEN_CHAT: "open-chat",
  CANCEL_REQUEST: "cancel-request",
  RESOLVE_REQUEST: "resolve-request",
};

const statusData = {
  OPEN: "Open",
  CLOSED: "Closed",
  COMPLETED: "Resolved",
  CANCELED: "Canceled",
  CREATED: "Created",
  BLOCKED: "Blocked",
  ONHOLD: "On hold",
  INPROGRESS: "In progress",
  NEW_MESSAGE: "Pending",
  WAITING_FOR_CLIENT: "Your reply needed",
};

const newPasswordValidation = {
  type: "The password must contain at least 1 character from at least 3 of the following 5\n categories of allowed characters:",
  validations: [
    'Uppercase letters (Latin, Greek, Cyrillic characters)',
    'Lowercase letters (Latin, Greek, Cyrillic characters)',
    'Characters of Asian languages',
    'Digits',
    'Special characters',
  ]
}

const statusDataUIView = {
  OPEN: "Pending",
  COMPLETED: "Resolved",
  CANCELED: "Canceled",
  BLOCKED: "Pending change",
  ONHOLD: "In progress",
  INPROGRESS: "In progress",
  DECLINED: "Declined",
  PENDING_EOD: "Pending EOD",
  WAITING_FOR_CLIENT: "Your reply needed",
  WAITING_FOR_3RD_PARTY: "In Progress",
  NEW_MESSAGE: "Pending",
  STALE: "Stale",
  CLOSED: "Closed",
  CREATED: "Created",
};

const typeData = {
  TRADER: "Trader",
  SYSTEM: "System",
  USER: "User",
};

const actionData = {
  ENABLE: "Enable",
  DISABLE: "Disable",
  INCREASE: "Increase",
  DECREASE: "Decrease",
  PENDING: "Pending",
};

const FormStatusTypes = {
  ONHOLD_STATUS: "On Hold",
  PENDING_STATUS: "Pending Approval",
  APPROVED_STATUS: "Approved",
  REJECTED_STATUS: "Rejected",
};

const SortReviewFieldsExample = [
  "title",
  "firstName",
  "middleName",
  "lastName",
  "dateofbirth",
  "citizenship",
  "pep",
  "phone",
  "street",
  "street2",
  "city",
  "postalCode",
  "country",
  "idtype",
  "idnumber",
  "idexpiration",
  "photoID",
  "photoIDback",
  "identity",
  "address",
];

const FormStatusTexts = {
  2: {
    [FormStatusTypes.ONHOLD_STATUS]:
      "Your application is on hold until additional information has been provided. See details below.",
    [FormStatusTypes.PENDING_STATUS]:
      "Our team is now reviewing your application. We will be in touch within 2 business days.",
    [FormStatusTypes.APPROVED_STATUS]:
      "Your trading application has been reviewed and approved!",
  },
  3: {
    STEP_3_NOT_APPROVED:
      "You will receive instructions of how to send the fee from our team after your application has been reviewed.",
    STEP_3_APPROVING_1: `Please send , Performance Bond using our ,.`,
    STEP_3_APPROVING_2: `Once the wire is sent, please confirm by clicking the button below.`,
    STEP_3_CLAIMED_1: "You confirmed the , was sent on ",
    STEP_3_CLAIMED_2:
      "Once our bank confirms receipt, we will proceed creating your trading platform.",
    STEP_3_APPROVED_1: "You confirmed the wire was sent ",
    STEP_3_APPROVED_2: "We received your wire ",
  },
  4: {
    STEP_4_NOT_APPROVED:
      "Once your application is approved, we will be able to create your trading platform.",
    STEP_4_APPROVING: "Our team is now setting up your trading platform.",
    STEP_4_APPROVED_1:
      "Your trading platform has been created. Please check your email for credentials.",
    STEP_4_APPROVED_2:
      "Please follow the instructions in an email we sent you to download ,",
  },
  5: {
    STEP_5_NOT_APPROVED:
      "Once your trading platform is created, we will be able to add your market data.",
    STEP_5_APPROVING_NOT_ST:
      "We are currently arranging subscription to the selected market data.",
    STEP_5_APPROVING_ST_1:
      "Please fill out NYSE agreements with Sterling here before we can add market data.",
    STEP_5_APPROVING_ST_2: "If you need help please follow the instructions.",
    STEP_5_APPROVING_ST_3: "Please note the following:",
    STEP_5_APPROVING_ST_3_a:
      "the NYSE agreement must be approved by the exchange prior to market data activation,",
    STEP_5_APPROVING_ST_3_b: "this approval can take up to 48 hours,",
    STEP_5_APPROVING_ST_3_c:
      "all other market data requests are enabled immediately.",
    STEP_5_APPROVED: "Your market data subscriptions were set up.",
  },
  6: {
    STEP_6_NOT_APPROVED: "",
    STEP_6_APPROVING_1:
      "When we create new accounts, they are disabled by default in order to avoid platform and market data fees.",
    STEP_6_APPROVING_2:
      "Trading platform providers and exchanges do not prorate their fees.",
    STEP_6_APPROVING_3:
      "If you would like to change this, please choose a better option:",
    STEP_6_APPROVING_4:
      "If you would like to enable it sooner, please choose a better option.",
    STEP_6_APPROVING_DATE: "Your account will be enabled automatically on ,",
    STEP_6_DATE_IS_SET: "Your account will be enabled on ,",
    STEP_6_SOON_IS_SET: "Your account will be enabled as soon as possible.",
    STEP_6_DATE_FEES: "Please note that the fees are not prorated.",
    STEP_6_ENABLE_TODAY:
      "Your account will be enabled today (or the next business day).",
    STEP_6_ENABLED: "Your account is already enabled.",
  },
  REJECTED:
    "Your application was rejected. Please contact support group for more information.",
};

const errorFieldsValues = {
  defaultInvalidField:
    "Use only Latin letters, dots, apostrophes and space between words",
  digitsInvalidField: "Use only digits, dashes, and space between characters",
};

const loginizationsPaths = {
  SIGN_IN: "sign-in",
  SIGN_OUT: "sign-out",
  LOGOUT: "logout",
}
const RequestTypes = {
  TRADING_ACCESS: "TRADING_ACCESS",
  MARKETDATA: "MARKETDATA",
  RISKSETTING: "RISKSETTING",
  INQUIRY: "INQUIRY",
  ALL: "ALL",
};

const mappedAccountSummaryData = {
  Volume: "Volume",
  OpgBalance: "Opening Balance",
  Deposits: "Deposits",
  AdjNet: "Net P&L (incl. fees)",
  Unrealized: "Unrealized P&L",
  TotalBank: "Current Balance",
};

const tokenNames = {
  LOG_IN_TOKEN: "portalToken",
};

const portalWorkflowTypeNames = {
  AccountSetup: "accountSetup",
  AccountOverview: "accountOverview",
  Simulator: "simulator",
  Documents: "documents",
};

const constants = {
  tokenNames,
  listForSimulatorAccount,
  portalWorkflowTypeNames,
  SortReviewFieldsExample,
  IDTypeList,
  TitleList,
  DocumentFormatList,
  FormStatusTexts,
  DocumentPermissionList,
  listForDocumentsAccount,
  DocumentStatusList,
  FormStatusTypes,
  errorFieldsValues,
  loginizationsPaths,
  listForEnabledAccount,
  RequestTypes,
  RequestFormStatusTypes,
  notActiveRequestsStates,
  actionData,
  listForNotEnabledAccount,
  typeData,
  statusData,
  statusDataUIView,
  newPasswordValidation,
  contextMenuOptionType,
  categoryData,
  mappedAccountSummaryData
};

export default constants;
