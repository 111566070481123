import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthAPI } from "../../../api/auth-api";
import { LOGIN_ERROR, TO_ACCOUNT_SETUP } from "../../../reducers/types";
import { authThunks } from "actions-thunks/auth-thunks";
import "./LoginPage.scss";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errorMessage } = useSelector((state) => state.errors);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    rememberme: true,
  });

  useEffect(() => {
    if (AuthAPI.getToken() || AuthAPI.getSessionToken()) {
      navigate(TO_ACCOUNT_SETUP);
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(authThunks.loginTC(formData));
  };

  const { username, password, rememberme } = formData;
  const loginErrorMessage = errorMessage?.find(i => i.type === LOGIN_ERROR)?.message;

  return (
    <div className="form-block">
      <Form onSubmit={handleSubmit} className="form-wrapper">
        <h3>Sign in</h3>
        <Form.Group className="form-group-block mb-3" controlId="formBasicEmail">
          <Form.Label className="form-label">Email</Form.Label>
          <Form.Control
            value={username}
            onChange={handleChange}
            name="username"
            type="email"
            placeholder="Enter email"
          />
        </Form.Group>

        <Form.Group className="form-group-block mb-3" controlId="formBasicPassword">
          <Form.Label className="form-label">Password</Form.Label>
          <Form.Control
            value={password}
            onChange={handleChange}
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
          />
          <span
            className="see-password"
            onClick={() => setShowPassword(!showPassword)}
          />
        </Form.Group>

        {loginErrorMessage && (
          <div className="error-message">
            <span>{loginErrorMessage}</span>
          </div>
        )}

        <Form.Group className="mb-3 spaced-between form-checkbox" controlId="formBasicCheckbox">
          <Form.Check
            name="rememberme"
            onChange={handleChange}
            checked={rememberme}
            type="checkbox"
            label="Remember me"
          />
        </Form.Group>

        <Button size="lg" variant="primary" type="submit" disabled={!username || !password}>
          Sign in
        </Button>
      </Form>
    </div>
  );
};

export default LoginPage;
