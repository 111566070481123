import React, { useMemo } from "react";
import { Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { setToastStateAC } from "../../../actions-thunks/actions";
import { ADM_ERROR_POPUP, ADM_SUCCESS_POPUP } from "../../../reducers/types";

import "./toast.scss";

const ToastComponent = () => {
    const {toastState} = useSelector((state) => state.errors);
    const {message, show, type} = toastState;
    const dispatch = useDispatch();

    const onCloseHandler = () => {
        dispatch(setToastStateAC({show: false, message: "", type: ""}));
    }

    const toastColor = useMemo(() => {
      switch (type) {
        case ADM_SUCCESS_POPUP:
          return "#90D0AA"
        case ADM_ERROR_POPUP:
          return "#e1786ed6"
        default:
          return "transparent";
      }
    }, [type]);

  return (
    <Toast style={{backgroundColor: toastColor}} show={show} onClose={onCloseHandler} autohide delay={5000}>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export default ToastComponent;
