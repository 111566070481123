import {
  TO_ACCOUNT_OVERVIEW,
  TO_ACCOUNT_SETUP,
  TO_FAQ,
  TO_MARKET_DATA,
  TO_PROFILE,
  TO_RESOURCES,
  TO_SIMULATOR,
  TO_STATEMENTS,
  TO_SUPPORT_PAGE,
} from "reducers/types";

export const navigationList = [
  {
    id: "accountoverview",
    destination: TO_ACCOUNT_OVERVIEW,
    summary: "Account Overview",
    icon: `${process.env.PUBLIC_URL}/icons/overview.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/overview-active.svg`,
    permission: "account",
  },
  {
    id: "simulator",
    destination: TO_SIMULATOR,
    summary: "Account Simulator",
    icon: `${process.env.PUBLIC_URL}/icons/overview.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/overview-active.svg`,
    permission: "account",
  },
  {
    id: "accountsetup",
    destination: TO_ACCOUNT_SETUP,
    summary: "Account Setup",
    icon: `${process.env.PUBLIC_URL}/icons/overview.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/overview-active.svg`,
    permission: "account",
  },
  {
    id: "marketdata",
    destination: TO_MARKET_DATA,
    summary: "Market Data",
    icon: `${process.env.PUBLIC_URL}/icons/marketdata.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/marketdata-active.svg`,
    permission: "account",
  },
  {
    id: "profile",
    destination: TO_PROFILE,
    summary: "Personal Information",
    icon: `${process.env.PUBLIC_URL}/icons/personal.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/personal-active.svg`,
    permission: "account",
  },
  {
    id: "monthlystatements",
    destination: TO_STATEMENTS,
    summary: "Monthly Statements",
    icon: `${process.env.PUBLIC_URL}/icons/monthly.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/monthly-active.svg`,
    permission: "account",
  },
  {
    id: "support",
    destination: TO_SUPPORT_PAGE,
    summary: "Support",
    icon: `${process.env.PUBLIC_URL}/icons/support.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/support-active.svg`,
    permission: "account",
  },
  {
    id: "etb",
    destination: TO_RESOURCES,
    summary: "ETB Lists",
    icon: `${process.env.PUBLIC_URL}/icons/resources.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/resources-active.svg`,
    permission: "account",
  },
  {
    id: "faq",
    destination: `${TO_FAQ}/617a7ac30b22ab563defcb5a`,
    summary: "FAQ",
    icon: `${process.env.PUBLIC_URL}/icons/faq.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/faq-active.svg`,
    permission: "account",
  },
];
