
import { useEffect, useState, useRef, useImperativeHandle, useMemo, forwardRef } from 'react';
import { Button } from '@lynx/ui-components';
import { useFormik } from 'formik';
import cn from 'classnames';
import { object, string } from 'yup';
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from 'react-textarea-autosize';
import axios from 'axios';

import constants from "lib/constants";
import {
  addSocketNamespaceAC,
  removeSocketNamespaceAC,
  deleteChatMessagesAC,
  setLoadingAC,
  setChatMessagesAC,
  setErrorAC
} from "actions-thunks/actions.js";
import { statisticAPI } from 'api/stats-api';
import { chatThunks } from "actions-thunks/chat-thunks.js";
import { requestsThunks } from "actions-thunks/requests-thunks";
import { GET_CHAT_MESSAGES_ERROR } from "reducers/types.js";
import { accountThunks } from "actions-thunks/account-thunks.js";
import { Upload } from 'ui/ui-kit/upload/Upload';
import Message from "./Message";
import { getActiveRequestID } from "./helpers";
import "./chatStyles.scss";

const emptyChatMessageDefault = `Communication with Support Team about this request will be displayed here`;

const Chat = forwardRef(({ emptyChatText = emptyChatMessageDefault, itemID, requestsItems }, ref) => {
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.chat);
  const { currentAccount } = useSelector((state) => state.accounts);
  const { RequestTypes } = constants;
  const requestsItemsDataALL = requestsItems?.[RequestTypes.ALL];
  const [localAttachments, setLocalAttachments] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const lastMessageRef = useRef(null);
  const cancelSource = useRef(axios.CancelToken.source());
  const currentRequestId = useMemo(() => getActiveRequestID(requestsItemsDataALL, itemID), [requestsItemsDataALL, itemID]);


  const cancelSourceOperation = () => {
    cancelSource.current.cancel('Operation canceled by the user.');
    cancelSource.current = axios.CancelToken.source();
  }

  // Expose the function to parent component
  useImperativeHandle(ref, () => ({
    cancelSourceOperation
  }));


  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: object({
      message: string().required('Required field')
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      let attachmentsIDsResponse = null;

      if (localAttachments.length > 0) {
        for (const file of localAttachments) {
          formData.append('attachments[]', file, file.name);
        }

        attachmentsIDsResponse = await dispatch(
          accountThunks.setAccountAttachmentsTC(
            currentAccount._id,
            { formData }
          )
        )
      }
      
      if (attachmentsIDsResponse?.status === 200 || attachmentsIDsResponse === null) {
        dispatch(
          requestsThunks.updateRequestStatusTC({
            accID: currentAccount._id,
            requestID: currentRequestId,
            action: 'send',
            body: {
              message: values.message,
              attachments: attachmentsIDsResponse?.data.attachments.map(item => item.id) ?? null
            }
          })
        );
      }
      statisticAPI.sendStatUsage(currentAccount._id, { page: 'Send a chat message' });

      setIsReset(true)
      formik.resetForm();
      setLocalAttachments([])
    },
  });

  useEffect(() => {
    if (isReset) {
      setIsReset(false)
    }
  }, [isReset]);

  useEffect(() => {
    let isMounted = true;
    try {
      dispatch(addSocketNamespaceAC({
        name: 'chat',
        data: {
          id: currentRequestId
        }
      }));

      async function getChatMessages() {
        dispatch(setLoadingAC(true));
        const messagesList = await dispatch(chatThunks.getChatMessagesTC(currentAccount._id, currentRequestId, cancelSource.current));
        if (isMounted && messagesList) {
          dispatch(setChatMessagesAC(messagesList))
          dispatch(setLoadingAC(false));
        }
      }
      getChatMessages();
    }
    catch (e) {
      dispatch(
        setErrorAC({
          type: GET_CHAT_MESSAGES_ERROR,
          message: e,
        })
      );
    }

    return () => {
      isMounted = false;
      dispatch(removeSocketNamespaceAC('chat'));
      dispatch(deleteChatMessagesAC());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300)
  }, [messages]);

  return (
    <div className="chat-inner-container">
      <div className="chat-messages-list">
        {messages && messages.length > 0 ?
          <div className="not-empty-chat">
            <ul>
              {messages.map((message) =>
                <Message
                  key={message._id}
                  message={message}
                />)}
            </ul>
            <div ref={lastMessageRef} />
          </div> :
          <p className="empty-chat">{emptyChatText}</p>
        }
      </div>
      <div className={cn("chat-message-field-area", { "error-field": formik.errors.subject })}>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <TextareaAutosize
              placeholder="Write your message here"
              id="message"
              name="message"
              onChange={formik.handleChange}
              value={formik.values.message}
            />

            <div className="actions">
              <Upload
                shouldReset={isReset}
                handleChange={(items) => setLocalAttachments(items)}
                tooltip={{
                  tooltipClassName: 'upload-tooltip',
                  tooltipContentClassName: 'upload-tooltip-content',
                  content: <span >
                    Max file size: 5 MB.< br />
                    Max number of attachments: 5. < br />
                    Allowed filetypes:<br />
                    <ul>
                      <li>text: PDF, CSV, XLS, XLSX, TXT</li>
                      <li>images: JPEG, JPG, PNG, GIF</li>
                      <li>video: MP4, MOV, WMV, AVI, FLV</li>
                    </ul>
                  </span>
                }}
              />
              <Button size="md" buttonStyle="button-variant-second" buttonClassName="cabinet-primary-btn" disabled={!formik.isValid || !formik.dirty} onClick={formik.handleSubmit} label="Send" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default Chat;
