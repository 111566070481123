import { instance, setHeader } from "./auth-api";

const accountUrl = "/account";

export const accountAPI = {
  getAccounts: async () => {
    const response = await instance.get(`application/list`, {
      headers: setHeader(),
    });
    return response;
  },
  getAccount: async (id) => {
    const response = await instance.get(`${accountUrl}/${id}`, {
      headers: setHeader(),
    });
    return response;
  },
  updateAccountInfo: async (id, data) => {
    const newBody = {...data, clientID: id}
    const response = await instance.post("application", newBody, {
      headers: setHeader(),
    });
    return response;
  },
  updateAccountReview: async (id, data) => {
    const response = await instance.post(`${accountUrl}/${id}/review`, data, {
      headers: setHeader(),
    });
    return response;
  },
  updateAccountStatus: async (id, data) => {
    const response = await instance.put(`${accountUrl}/${id}/status`, data, {
      headers: setHeader(),
    });
    return response;
  },
  setAttachments: async (id, data = {}) => {
    const response = await instance.post(`/chat/attachments/${id}`, data, {
      headers: setHeader(),
    });
    return response;
  },
  getAttachment: async (accID, attachmentID, cancelToken) => {
    const data = {
      headers: setHeader(),
      responseType: "blob",
    };
    if (cancelToken) {
      data.cancelToken = cancelToken.token;
    }
    const response = await instance.get(
      `/chat/attachments/${accID}/${attachmentID}`,
      data
    );
    return response;
  },
  uploadImage: async (id, data) => {
    const formData = new FormData();
    formData.append("kind", data.kind);
    formData.append("scan", data.image);
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        ...setHeader(),
      },
    };
    const response = await instance.put(
      `${accountUrl}/${id}/scans`,
      formData,
      options
    );
    return response;
  },
  removeImage: async (id, scanId) => {
    const response = await instance.delete(
      `${accountUrl}/${id}/scans/${scanId}`,
      { headers: setHeader() }
    );
    return response;
  },
  getSupportiveEntities: async (value = "country") => {
    const response = await instance.get(`/static/${value}`, {
      headers: setHeader(),
    });
    return response;
  },
  getStatusHistory: async (accID) => {
    const response = await instance.get(`application/status-history/${accID}`, {
      headers: setHeader(),
    });
    return response;
  },
  getExtensionData: async (accID) => {
    const response = await instance.get(`application/ext/${accID}`, {
      headers: setHeader(),
    });
    return response;
  },
  claimWire: async (accID, body) => {
    const newBody = {
      ...body,
      applicationID: accID,
      source: "Trader's portal",
      status: "claimed",
    };
    const response = await instance.post("wire", newBody, {
      headers: setHeader(),
    });
    return response;
  },
  sendWireImage: async (accID, wireID, data) => {
    try {
        if (!(data.file instanceof File)) {
            console.error("The provided data.file is not a valid File object");
            return;
        }

        const formData = new FormData();
        formData.append("kind", data.kind);
        formData.append("confirmation", data.file);
        formData.append("applicationID", accID);
        formData.append("wireID", wireID);
        formData.append("source", "Trader's portal");
        formData.append("status", "claimed");
        const response = await instance.post("wire", formData, {
            headers: {
                ...setHeader(),
            },
        });

        return response;
    } catch (error) {
        console.error("Error in sendWireImage:", error);
        throw error;
    }
},



  getWireImage: async (wireID) => {
    const response = await instance.get(`/wire/${wireID}/confirmation`, {
      headers: setHeader(),
      responseType: "blob",
    });
    return response;
  },
  removeWireImage: async (wireID) => {
    const response = await instance.delete(`/wire/${wireID}/confirmation`, {
      headers: setHeader(),
    });
    return response;
  },
  removeWire: async (accID, wireID) => {
    const response = await instance.post(
      "/wire",
      { applicationID: accID, wireID, status: "pending" },
      { headers: setHeader() }
    );
    return response;
  },
  getWiresList: async (accID) => {
    const response = await instance.get(`/wire/list`, {
      headers: setHeader(),
      params: { type: "initial", applicationID: accID },
    });
    return response;
  },
};
