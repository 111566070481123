import { isValidElement } from "react";
import moment from "moment";

import constants from "lib/constants";
import { utilityFunctions } from "./utilityFunctions";
import { supportTableUtils } from "ui/ui-kit/react-table/supportTableUtils";
import ConfirmationModal from "ui/components/modals/confirmation/ConfirmationModal";
import ChatModal from "ui/components/chat/ChatModal";

const {
  categoryData,
  notActiveRequestsStates,
  typeData,
  statusData,
  statusDataUIView,
  actionData,
  contextMenuOptionType,
  RequestTypes,
} = constants;

const { tableStatusTimeFormat } = supportTableUtils;

export const requestTableUtils = {
  showInOverlay: (props) => {
    if (props.row) {
      const options = [];
      const {
        row,
        isLastItem,
        showModal,
        hideModal,
        cancelRequest,
        MenuContext,
        requestsItemsDataALL,
      } = props;

      const showCancelRequestItem = () => {
        // First, check the state of the row
        const isStateValid =
          row.original.state === statusData.OPEN ||
          row.original.state === statusData.NEW_MESSAGE ||
          (isValidElement(row.original.state) &&
            row.original.state?.props?.children[0]?.props?.children ===
              statusData.OPEN);

        // If the state is not valid, we can return false early
        if (!isStateValid) {
          return false;
        }
        const foundRowItemInRequests = requestsItemsDataALL?.find(
          (r) => r.sequenceNumber === row.original.id
        );

        if (foundRowItemInRequests) {
          return !(
            foundRowItemInRequests.type === RequestTypes.RISKSETTING &&
            foundRowItemInRequests.initiator === "SYSTEM"
          );
        }

        return true;
      };

      if (
        row.original.category === categoryData.CUSTOM ||
        row.original.category === categoryData.RISKSETTING
      ) {
        options.push({
          title: "Open Chat",
          href: null,
          onClick: () =>
            showModal(<ChatModal hideModal={hideModal} item={row} />),
        });
      }

      if (showCancelRequestItem()) {
        options.push({
          title: "Cancel request",
          href: contextMenuOptionType.CANCEL_REQUEST,
          onClick: () =>
            showModal(
              <ConfirmationModal
                isApplyButtonRed
                showEditModal
                closeModal={hideModal}
                onApplyClick={cancelRequest}
                item={row}
                labels={{
                  title: "Cancel request?",
                  body: "Do you really want to cancel this request?",
                  buttons: { apply: "Yes", decline: "No" },
                }}
              />
            ),
        });
      }

      if (
        row.original.category === categoryData.CUSTOM &&
        row.original.state === statusData.WAITING_FOR_CLIENT
      ) {
        options.push({
          title: 'Mark this request "Resolved"',
          href: contextMenuOptionType.RESOLVE_REQUEST,
          onClick: () =>
            showModal(
              <ConfirmationModal
                showEditModal
                closeModal={hideModal}
                onApplyClick={cancelRequest}
                item={row}
                labels={{
                  title: "Confirm request resolution?",
                  body: "Do you really want to mark this request as resolved?",
                  buttons: { apply: "Yes", decline: "no" },
                }}
              />
            ),
        });
      }

      if (!!options.length) {
        const isDesktop = window.innerWidth >= 980;
        const renderPart = (
          <td className="hover-content">
            <MenuContext
              side={isLastItem && "top"}
              className="row-overlay-menu"
              styleType="zimtra-primary"
              options={options}
            />
          </td>
        );

        return isDesktop ? (
          renderPart
        ) : (
          <div className="relative-wrapper">{renderPart}</div>
        );
      }
    }
  },

  setCellClassName: (cell) => {
    if (cell && cell.value === statusData.WAITING_FOR_CLIENT) {
      return "your-reply-needed";
    }
    if (
      cell &&
      (cell.column?.Header === "created" || cell.column?.Header === "updated")
    ) {
      return "no-wrap-cell";
    }
  },

  getCurrentAction: (name, arrayOfActions) => {
    let formattedAction = name;

    arrayOfActions?.forEach((obj) => {
      if (obj.name === name) {
        formattedAction = obj.label;
      }
    });

    return formattedAction;
  },

  formatStateData: (item, note, formattedStatus) => {
    if (!note && item?.initiator !== "SYSTEM") return formattedStatus;
    if (note?.source === "phone" || item?.initiator === "SYSTEM") {
      let returnValue = null;
      const action = notActiveRequestsStates.includes(item.status)
        ? "canceled"
        : "created";
      const date = moment(item.createdAt).format("MMMM DD, yyyy");
      const time = moment(item.createdAt).format("h:mm A");
      if (note?.source === "phone") {
        returnValue = `This request was ${action} by Support on ${date} at ${time}`;
      } else {
        returnValue = `Note: This request was automatically created by System`;
      }

      return (
        <div>
          <div>{formattedStatus}</div>
          <br />
          <div className="fs-italic">{returnValue}</div>
        </div>
      );
    }
  },

  summaryList: (ext = {}, schedule, riskSettingMetadata) => {
    const {
      addMarkets = [],
      removeMarkets = [],
      field,
      action,
      oldValue,
      value,
      subject,
      attachments,
    } = ext;

    if (addMarkets.length || removeMarkets.length) {
      const addMarketsList = addMarkets.map((m) => m.market).join(", ");
      const removeMarketsList = removeMarkets.map((m) => m.market).join(", ");

      return (
        <div className="support-table-summary">
          <div>Apply the following changes:</div>
          <ul className="mb-0">
            {addMarketsList && <li>add {addMarketsList}</li>}
            {removeMarketsList && <li>remove {removeMarketsList}</li>}
          </ul>
          <div>
            Due date: {moment.utc(schedule?.duedate).format("YYYY-MM-DD")}
          </div>
        </div>
      );
    }

    if (field) {
      let fieldAction;

      if (Number(value) > Number(oldValue)) {
        fieldAction = `${actionData.INCREASE}`;
      } else if (Number(value) < Number(oldValue)) {
        fieldAction = `${actionData.DECREASE}`;
      }

      return `${fieldAction} ${requestTableUtils.getCurrentAction(
        field,
        riskSettingMetadata
      )} from ${
        utilityFunctions.currencyFormatter().format(oldValue)?.split(".")[0]
      }
      to ${utilityFunctions.currencyFormatter().format(value)?.split(".")[0]}`;
    }

    if (action) {
      return (
        <div>{`${
          actionData[action] || action
        } my account on ${utilityFunctions.userTime(
          schedule?.duedate,
          "YYYY-MM-DD"
        )}`}</div>
      );
    }

    if (attachments) {
      return <div className="support-table-summary">{`${subject}`}</div>;
    }
  },
  tableFormattedDataForEachElement: (item, riskSettingMetadata) => {
    const {
      sequenceNumber,
      type,
      initiator,
      status,
      note,
      updatedAt,
      createdAt,
      schedule,
      subtype,
      ext,
    } = item;
    let formattedCategory = categoryData.CUSTOM;
    let formattedStatus = status;
    let formattedInitiator = initiator;
    let formattedSubtype = subtype || "N/A";

    if (categoryData[type]) {
      formattedCategory = categoryData[type];
    }
    if (typeData[initiator]) {
      formattedInitiator = typeData[initiator];
    }
    if (statusDataUIView[status]) {
      formattedStatus = statusDataUIView[status];

      if (
        (formattedCategory === categoryData.MARKETDATA &&
          formattedInitiator !== typeData.TRADER &&
          (formattedStatus === statusData.CREATED ||
            formattedStatus === statusData.CANCELED)) ||
        ((formattedInitiator === typeData.USER ||
          formattedInitiator === typeData.SYSTEM) &&
          formattedStatus === statusData.CREATED)
      ) {
        formattedStatus = (
          <div>
            {formattedStatus}
            <div className="account-overview-table-note">
              {`Note: This request was ${formattedStatus} by Support on ${tableStatusTimeFormat(
                createdAt
              )}`}
            </div>
          </div>
        );
      }
    }

    if (formattedCategory === categoryData.RISKSETTING) {
      formattedSubtype = schedule?.type === "EOD" ? "EOD" : "Permanent";
    }

    return {
      id: sequenceNumber || "N/A",
      category: formattedCategory,
      summary:
        requestTableUtils.summaryList(ext, schedule, riskSettingMetadata) ||
        "N/A",
      type: formattedSubtype,
      state: requestTableUtils.formatStateData(item, note, formattedStatus),
      updated: updatedAt,
      created: createdAt,
    };
  },

  tableFormattedData: (
    onlyActive,
    requestsItemsDataALL,
    riskSettingMetadata
  ) => {
    let RI = requestsItemsDataALL.map((i) => i);

    if (onlyActive) {
      RI = requestsItemsDataALL?.filter(
        (r) => !notActiveRequestsStates.includes(r.status)
      );
    }

    if (RI?.length)
      return RI?.map((item) =>
        requestTableUtils.tableFormattedDataForEachElement(
          item,
          riskSettingMetadata
        )
      );

    return [
      {
        id: "N/A",
        category: "N/A",
        summary: "N/A",
        type: "N/A",
        state: "N/A",
        updated: "N/A",
        created: "N/A",
      },
      "no-data",
    ];
  },
};
