/* eslint-disable */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Icon } from "@lynx/ui-components";

import { TO_ACCOUNT_OVERVIEW, TO_ACCOUNT_SETUP } from "reducers/types";
import constants from "lib/constants";
import { profileThunks } from "actions-thunks/profile-thunks";

import "./Simulator.scss";
import { accountThunks } from "actions-thunks/account-thunks";

const SVGs = {
  inprogress: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="8" fill="#F7C948" />
    </svg>
  ),
  created: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.5303 5.53033C12.8232 5.23744 12.8232 4.76256 12.5303 4.46967C12.2374 4.17678 11.7626 4.17678 11.4697 4.46967L6.5 9.43934L4.53033 7.46967C4.23744 7.17678 3.76256 7.17678 3.46967 7.46967C3.17678 7.76256 3.17678 8.23744 3.46967 8.53033L5.96967 11.0303C6.26256 11.3232 6.73744 11.3232 7.03033 11.0303L12.5303 5.53033Z"
        fill="#67DEAA"
      />
    </svg>
  ),
  expired: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.999 3.34038C18.5073 4.21124 19.762 5.46091 20.6388 6.96568C21.5157 8.47046 21.9844 10.1782 21.9985 11.9197C22.0125 13.6613 21.5715 15.3763 20.719 16.8951C19.8666 18.4138 18.6323 19.6836 17.1383 20.5787C15.6443 21.4738 13.9424 21.9632 12.2011 21.9984C10.4599 22.0337 8.73957 21.6136 7.21058 20.7796C5.68158 19.9457 4.39689 18.7269 3.48369 17.2439C2.57049 15.7609 2.06043 14.0651 2.00402 12.3244L1.99902 12.0004L2.00402 11.6764C2.06003 9.94937 2.56257 8.26634 3.46266 6.79137C4.36275 5.31641 5.62968 4.09983 7.13992 3.26026C8.65015 2.42069 10.3522 1.98678 12.08 2.00082C13.8079 2.01486 15.5026 2.47638 16.999 3.34038ZM10.51 9.14038C10.3005 9.01566 10.0526 8.97179 9.81303 9.01701C9.57343 9.06224 9.35861 9.19345 9.20897 9.38596C9.05933 9.57848 8.98519 9.81903 9.00048 10.0624C9.01578 10.3057 9.11946 10.5351 9.29202 10.7074L10.584 12.0004L9.29202 13.2934L9.20902 13.3874C9.05361 13.5884 8.98053 13.841 9.00462 14.0939C9.02872 14.3468 9.14818 14.5811 9.33875 14.7491C9.52931 14.9172 9.77669 15.0064 10.0306 14.9986C10.2846 14.9909 10.5261 14.8867 10.706 14.7074L11.999 13.4154L13.292 14.7074L13.386 14.7904C13.587 14.9458 13.8396 15.0189 14.0925 14.9948C14.3455 14.9707 14.5797 14.8512 14.7478 14.6607C14.9158 14.4701 15.005 14.2227 14.9973 13.9688C14.9895 13.7148 14.8854 13.4733 14.706 13.2934L13.414 12.0004L14.706 10.7074L14.789 10.6134C14.9444 10.4124 15.0175 10.1598 14.9934 9.90686C14.9693 9.65393 14.8499 9.41966 14.6593 9.25163C14.4687 9.0836 14.2214 8.9944 13.9674 9.00215C13.7135 9.00991 13.472 9.11403 13.292 9.29338L11.999 10.5854L10.706 9.29338L10.612 9.21038L10.51 9.14038Z"
        fill="#EF4E4E"
      />
    </svg>
  ),
};

const Copy = `${process.env.PUBLIC_URL}/copy.svg`;

const { portalWorkflowTypeNames } = constants;

const Simulator = () => {
  const { portalWorkflowType } = useSelector((state) => state.profile);
  const { currentAccount, extensions, accounts } = useSelector((state) => state.accounts);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [copiedElement, setCopiedElement] = useState(null);

  useEffect(() => {
    if (typeof portalWorkflowType === "string" && currentAccount) {
      if (portalWorkflowType === portalWorkflowTypeNames.AccountSetup) {
        navigate(TO_ACCOUNT_SETUP);
      }
      if (portalWorkflowType === portalWorkflowTypeNames.AccountOverview) {
        navigate(TO_ACCOUNT_OVERVIEW);
      }
    }
  }, [portalWorkflowType, navigate, currentAccount]);

  useEffect(() => {
    if (currentAccount) {
      dispatch(accountThunks.setAccountExtensionsTC(currentAccount._id));
    }
  }, [currentAccount]);

  const upgradeSection = () => {
    const onUpgradeHndleClick = (e) => {
      e.preventDefault();
      dispatch(profileThunks.upgradeSimulatorTC(accounts));
    };
    return (
      <div className="sim-upgrade-section">
        <Icon className="info-icon" name="info" />
        <div className="sim-upgrade-button">
          <span>Want live account? Hit the button</span>
          <Button
            size="sm"
            buttonStyle="zimtra-secondary"
            buttonClassName="edit-profile-main-button"
            onClick={onUpgradeHndleClick}
            label="Upgrade"
          />
        </div>
      </div>
    );
  };

  const login = currentAccount?.reference?.client;
  const passpord = extensions?.find((ext) => ext.code === "trading/platform")
    ?.ext.reportData?.password;

  const onCopyClick = (type) => {
    if (type === "login") {
      navigator.clipboard.writeText(login);
    } else {
      navigator.clipboard.writeText(passpord);
    }
    setCopiedElement(type)
  };

  const renderBodySection = (status) => {
    switch (status) {
      case "inprogress":
        return (
          <div className="sim-upgrade-section">
            {SVGs[status]}
            <div className="g-8 d-flex flex-col align-items-baseline">
              <span>Our team is now setting up your simulator.</span>
              <span className="sm-grey">
                Thank You for completing your trading application!
              </span>
            </div>
          </div>
        );
      case "created":
        return (
          <div className="sim-upgrade-section">
            {SVGs[status]}
            <div className="sim-upgrade-section-body">
              <span>Your simulator has been created</span>
              <span className="sm-14">
                Download Sterling client via&nbsp;
                <a className="link" href="https://portal.sterlingtradingtech.com/software-download/">
                  link
                </a>
              </span>
              <div>
                <span className="sm-grey">Login:</span>
                <span className="sm-bolder">{login || "Login"}</span>

                {copiedElement === "login" ? (
                  <Icon className="check-icon" name="check" />
                ) : (
                  <img
                    onClick={() => onCopyClick("login")}
                    src={Copy}
                    alt="Copy"
                  />
                )}
              </div>
              <div>
                <span className="sm-grey">Password:</span>
                <span className="sm-bolder">{passpord || "Password"}</span>
                {copiedElement === "pass" ? (
                  <Icon className="check-icon" name="check" />
                ) : (
                  <img
                    onClick={() => onCopyClick("pass")}
                    src={Copy}
                    alt="Copy"
                  />
                )}
              </div>
            </div>
          </div>
        );
      case "expired":
        return (
          <div className="sim-upgrade-section">
            {SVGs[status]}
            <span>Your simulator has been expired.</span>
          </div>
        );
      default:
        return null;
    }
  };

  const setSimStatus = () => {
    const createdAccountStatuses = [
      "AccountEnabled",
      "AccountSuspended",
      "AccountCreated",
    ];
    if (createdAccountStatuses.includes(currentAccount?.status)) {
      return "created";
    } else if (!createdAccountStatuses.includes(currentAccount?.status)) {
      return "inprogress";
    } else {
      return "expired";
    }
  };

  if (currentAccount)
    return (
      <div className="account-setup-wrapper">
        <h4 className="account-setup-header" id="simulator">
          Simulator
        </h4>
        <div className="sim-body">
          {renderBodySection(setSimStatus())}
          {upgradeSection()}
        </div>
      </div>
    );
  return null;
};

export default Simulator;
