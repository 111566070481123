import { useState, useEffect } from "react";

import "./state-controller.scss";

const StatementsStateController = ({ items, children }) => {
  const getMostRecentYear = () => {
    const years = items.map((item) => new Date(item.period).getFullYear());
    return years.length > 0 ? Math.max(...years) : new Date().getFullYear();
  };

  const [currentItemFilter, setCurrentItemFilter] = useState(getMostRecentYear);

  const getFilteredItemsLength = (year) =>
    items.filter((item) => new Date(item.period).getFullYear() === year).length;

  // Initialize endIndexNumber based on the most recent year's items
  const [endIndexNumber, setEndIndexNumber] = useState(() =>
    getFilteredItemsLength(getMostRecentYear())
  );

  useEffect(() => {
    setEndIndexNumber(
      items.filter(
        (item) => new Date(item.period).getFullYear() >= currentItemFilter
      ).length
    );
  }, [currentItemFilter, items]);

  const handleLoadMore = () => {
    setCurrentItemFilter(currentItemFilter - 1);
  };

  const handleLoadAll = () => {
    setEndIndexNumber(items.length);
  };

  // Determine if there are more items to show for the "Show More" functionality
  const moreItemsToShow = endIndexNumber < items?.length

  return (
    <>
      {children.slice(0, endIndexNumber)}
      <div className="pagination-wrapper">
        <span>
          Showing {Math.min(endIndexNumber, children.length)} of{" "}
          {children.length} total
        </span>
        {moreItemsToShow && (
          <>
            <hr className="vertical-hr" />
            <span onClick={handleLoadMore}>
              Show {getFilteredItemsLength(currentItemFilter - 1)} more
            </span>
          </>
        )}
        {moreItemsToShow && (
          <>
            <hr className="vertical-hr" />
            <span onClick={handleLoadAll}>Show all</span>
          </>
        )}
      </div>
    </>
  );
};

export default StatementsStateController;
