import { chatAPI } from "api/chat-api";
import { GET_CHAT_MESSAGES_ERROR } from "../reducers/types";
import { setErrorAC, setLoadingAC } from "./actions";
import { accountThunks } from "./account-thunks";

//chat thunks
export const chatThunks = {
  getChatMessagesTC: (accID, requestId, cancelToken) => async (dispatch) => {
    try {
      const response = await chatAPI.getChatMessages(accID, requestId);
      if (response.status === 200 && response.data?.messages) {
        const filteredMessages = response.data?.messages.filter(
          (item) => item.messageType === "PUBLIC"
        );
        const allPublicMessagesDataPromises = filteredMessages.map((item) => {
          if (item.attachments.length > 0) {
            const singleMessageAttachments = item.attachments.map((item) =>
              dispatch(
                accountThunks.getAccountAttachmentTC(
                  accID,
                  item,
                  true,
                  cancelToken
                )
              )
            );
            return Promise.all(singleMessageAttachments).then((attachment) => {
              return attachment.includes(undefined)
                ? item
                : {
                    ...item,
                    attachmentsData: attachment,
                  };
            });
          } else {
            return Promise.resolve(item);
          }
        });
        return Promise.all(allPublicMessagesDataPromises).then(
          (allMessagesData) => {
            return allMessagesData;
          }
        );
      }
      dispatch(
        setErrorAC({
          type: GET_CHAT_MESSAGES_ERROR,
          message: "",
        })
      );
    } catch (error) {
      dispatch(setLoadingAC(false));
      if (error.response.data.message === "Chat is not started") {
      } else {
        dispatch(
          setErrorAC({
            type: GET_CHAT_MESSAGES_ERROR,
            message: error.response.data?.error?.msg,
          })
        );
      }
    }
  },
};
