import cn from 'classnames';
import Markdown from 'markdown-to-jsx';

import { supportTableUtils } from "ui/ui-kit/react-table/supportTableUtils";
import SupportIcon from "images/support-face.svg"
import "./chatStyles.scss";

const { tableTimeFormat } = supportTableUtils;

const Attachment = ({ attachment }) => {
  const handleDownload = () => {
    const attachmentUrl = URL.createObjectURL(attachment.file.data);
    const link = document.createElement('a');
    link.href = attachmentUrl;
    link.download = attachment.info.name;
    link.click();
  };

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <div className="attachment" key={attachment._id}><a href="#" onClick={handleDownload}>
    {attachment.info.name}
  </a></div>
};

const Message = ({ message }) => {

  return (
    <li
      className={cn(
        { "customer-message": message.author.name === "Trader" },
        { "support-message": message.author.name !== "Trader" }
      )}>
      <div className={"inner-container"}>
        <div className="message-avatar">
          {message.author.name !== "Trader" &&
            <div className="avatar picture support">
              <img src={SupportIcon} alt="Support" />
            </div>
          }
        </div>
        <div className="message-content">
          <div className='area'>
            <div className="message-text"><pre><Markdown>{message.message}</Markdown></pre></div>
          </div>
          {message.attachmentsData && message.attachmentsData.map((attachment) => <Attachment key={attachment.info._id} attachment={attachment} />)}
          <div className='info'>
            <div className="message-date">{tableTimeFormat(message.date)}</div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Message;