import { instance, setHeader } from "./auth-api";

export const statisticAPI = {
  sendStatUsage: async (accID, data) => {
    const response = await instance.post(`/application/${accID}/portal-usage/`, data, {
      headers: setHeader(),
    });
    return response;
  },
};
