import React from "react";
import { Spinner } from "react-bootstrap";

import constants from "../../../lib/constants";
import { reviewStatuses } from "../../../reducers/types";
import ReviewFields from "../review-field/ReviewField";
import { utilityFunctions } from "../../utilities/utilityFunctions";

import "./stepper.scss";

const VerticalStepper = ({
  steps,
  dateIsSet,
  wireSent,
  requestsItems,
  wiresList,
  portalWorkflowType,
  currentStep,
  currentAccount,
  statusHistory,
}) => {
  const renderSteps = steps.map((step, index) => {
    return (
      <Step
        key={index + "-" + step.name}
        step={step}
        wireSent={wireSent}
        wiresList={wiresList}
        requestsItems={requestsItems}
        statusHistory={statusHistory}
        dateIsSet={dateIsSet}
        portalWorkflowType={portalWorkflowType}
        currentStep={currentStep}
        currentAccount={currentAccount}
        reviews={step.reviews}
      />
    );
  });
  return <div className="v-stepper-wrapper">{renderSteps}</div>;
};

export default VerticalStepper;

const Step = ({
  step,
  currentStep,
  dateIsSet,
  requestsItems,
  wireSent,
  wiresList,
  reviews,
  statusHistory,
  portalWorkflowType,
  currentAccount,
}) => {
  const { FormStatusTypes, RequestTypes } = constants;

  const checkWiresListStatus = (status) => {
    return !!wiresList?.length && wiresList.at(-1).status === status;
  };
  const setBackgroundColor = () => {
    if (step.step <= currentStep.step) return "done-step-background";
    if (currentAccount.status === FormStatusTypes.REJECTED_STATUS)
      return "rejected-step-background";
    return "not-done-step-background";
  };
  const setBackgroundColorLeft = () => {
    if (step.step < currentStep.step) return "done-step-background";
    if (currentAccount.status === FormStatusTypes.REJECTED_STATUS)
      return "rejected-step-background";
    return "not-done-step-background";
  };
  const setFontWeight = () => {
    if (currentStep.step === step.step) return "current-step-name";
    return undefined;
  };
  const setFontColor = () => {
    if (step.step === 6 && checkWiresListStatus("received")) return undefined;
    if (step.step > currentStep.step) return "not-done-step-color";
    return undefined;
  };
  const setOnHoldColor = (bg) => {
    if (step.step === 3 && wireSent !== null && !wireSent) {
      return "status-yellow-bg";
    }
    if (
      step.step === 5 &&
      currentStep.step === 5 &&
      currentAccount.software === "Sterling"
    )
      return "status-yellow-bg";
    return step.status === FormStatusTypes.ONHOLD_STATUS
      ? bg
        ? "status-yellow-bg"
        : "status-yellow-font"
      : "undefined";
  };

  const setUltimateDate = () => {
    if (
      !utilityFunctions.isEmptyObj(currentAccount) &&
      step.step === 1 &&
      statusHistory
    ) {
      const foundItem = statusHistory.find((s) => s.status === "Received");
      let transformedFormat = "N/A";
      if (foundItem) {
        transformedFormat = utilityFunctions.transformToStepDateView(
          foundItem?.date
        );
      }
      return `on ${transformedFormat}`;
    }
    if (
      !utilityFunctions.isEmptyObj(currentAccount) &&
      step.step === 2 &&
      statusHistory &&
      currentStep.step > step.step
    ) {
      const foundItem = statusHistory.find((s) => s.status === "Approved");
      let transformedFormat = "N/A";
      if (foundItem) {
        transformedFormat = utilityFunctions.transformToStepDateView(
          foundItem.date
        );
      }
      return `on ${transformedFormat}`;
    }
    if (
      !utilityFunctions.isEmptyObj(currentAccount) &&
      !!wiresList?.length &&
      (wiresList.at(-1)?.claim || wiresList.at(-1)?.received) &&
      currentStep.step > 3 &&
      step.step === 3
    ) {
      const statusDate = wiresList[wiresList.length - 1]["received"]?.date;
      const transformedFormat = utilityFunctions.transformToStepDateView(
        statusDate,
        "noHours"
      );
      const textOn = `on ${transformedFormat}`;
      return textOn;
    }
    if (
      !utilityFunctions.isEmptyObj(currentAccount) &&
      currentStep.step > 4 &&
      step.step === 4 &&
      statusHistory
    ) {
      const getStatusTime = statusHistory?.find(
        (f) => f.status === "AccountCreated"
      );
      let transformedFormat = "N/A";
      if (getStatusTime) {
        transformedFormat = utilityFunctions.transformToStepDateView(
          getStatusTime?.date
        );
      }
      return `on ${transformedFormat}`;
    }
    if (
      !utilityFunctions.isEmptyObj(currentAccount) &&
      requestsItems?.find((item) => item.type === RequestTypes.TRADING_ACCESS)
        ?.ext.action === "ENABLE" &&
      currentStep.step > 5 &&
      step.step === 5
    ) {
      const getStatusTime = requestsItems?.find(
        (item) => item.type === RequestTypes.TRADING_ACCESS
      )?.time["COMPLETED"];
      const transformedFormat =
        utilityFunctions.transformToStepDateView(getStatusTime);
      return `on ${transformedFormat}`;
    }
    return null;
  };

  const dataIsFetchingSpinner = () => {
    if (currentStep.step === 3 && step.step === 3 && wiresList === null) {
      return (
        <div className="account-setup-wrapper">
          <Spinner className="spinner" animation="grow" />
        </div>
      );
    }
  };

  return (
    <div
      className={`${
        step.step === 1
          ? "first-step-wrapper"
          : step.step === 6
          ? "last-step-wrapper"
          : "step-wrapper"
      } `}
    >
      <div className={`step-name ${setFontColor()}`}>
        <span className={setFontWeight()}>
          {step.name} {`${step.status} `} {dataIsFetchingSpinner()}
        </span>
        {setUltimateDate()}
      </div>
      {step.text && <div className="step-text">{step.text}</div>}
      {step.text2 && <div className="step-text2">{step.text2}</div>}
      {reviews !== undefined &&
        portalWorkflowType &&
        reviews.some((r) => r.status === reviewStatuses.DEFICIENT) && (
          <ReviewFields reviews={reviews} />
        )}
      {step.utilityField}
      <div className="v-step-line-wrapper">
        <div
          className={`step-circle ${setBackgroundColor()} ${setOnHoldColor(
            true
          )}`}
        >
          {step.step < currentStep.step && (
            <img src={`${process.env.PUBLIC_URL}/tick.svg`} alt="tick" />
          )}
        </div>
        {step.step < 6 && (
          <div
            className={`short-line ${setBackgroundColorLeft()} ${setOnHoldColor(
              true
            )}`}
          />
        )}
        {step.step === 6 && currentStep.step === 6 && (
          <div
            className={`short-line ${
              currentAccount?.status === "AccountEnabled"
                ? "done-step-background"
                : dateIsSet
                ? "not-done-step-background"
                : "status-yellow-bg"
            }`}
          />
        )}
      </div>
    </div>
  );
};
