import "./attachment-download.scss";

const AttachmentDownload = ({ label, onDownloadClick }) => (
  <div className="attachment-download-page-wrapper" onClick={onDownloadClick}>
    {label && <span>{label}</span>}
    <img src={`${process.env.PUBLIC_URL}/icons/download.svg`} alt="download" />
  </div>
);

export default AttachmentDownload;
