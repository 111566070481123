import { requestsAPI } from "api/requests-api";
import constants from "lib/constants";
import {
  CREATE_DATA_REQUEST_ERROR,
  GET_ACCOUNT_REQUESTS_ERROR,
  GET_RIST_SETTINGS_METADATA_ERROR,
  UPDATE_ACCOUNT_REQUESTS_ERROR,
  UPDATE_REQUEST_STATUS_ERROR,
} from "../reducers/types";
import {
  setErrorAC,
  setLoadingAC,
  setAccRequestsAC,
  setRiskSettingsMetadataAC,
  addAccRequestAC,
  updateAccRequestAC,
  addAccRequestsAC,
  setTotalRequestItemsAC,
} from "./actions";
import { chatThunks } from "./chat-thunks";

//account thunks
export const requestsThunks = {
  getAccountRequestsTC:
    ({ accID, type, statusCode = "", addingRequests }) =>
      async (dispatch) => {
        try {
          dispatch(setLoadingAC(true))
          const response = await requestsAPI.getRequests(
            accID,
            type,
            statusCode,
          );
          if (response.status === 200) {
            if (type) {
              dispatch(setAccRequestsAC({ [type]: response.data.items }));
            } else {
              let separatedRequests = [];
              if (response.data.items.length) {
                separatedRequests = response.data.items.reduce((acc, item) => {
                  const existingItem = acc.find((group) => group[item.type]);
                  if (existingItem) {
                    existingItem[item.type].push(item);
                  } else {
                    acc.push({ [item.type]: [item] });
                  }
                  return acc;
                }, []);
              }
              separatedRequests.push({
                [constants.RequestTypes.ALL]: response.data.items,
              });
              dispatch(setTotalRequestItemsAC(response.data.total));
              if (addingRequests) {
                dispatch(addAccRequestsAC(separatedRequests));
              } else {
                separatedRequests.forEach((item) =>
                  dispatch(setAccRequestsAC(item))
                );
              }
            }
          }
          dispatch(
            setErrorAC({
              type: GET_ACCOUNT_REQUESTS_ERROR,
              message: "",
            })
          );
        } catch (error) {
          dispatch(
            setErrorAC({
              type: GET_ACCOUNT_REQUESTS_ERROR,
              message: error.response.data?.message,
            })
          );
        } finally {
          dispatch(setLoadingAC(false));
        }
      },
  updateAccountRequestsTC: (accID, id, body) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await requestsAPI.updateRequests(id, body);
      if (response.status === 200) {
      }
      dispatch(
        setErrorAC({
          type: UPDATE_ACCOUNT_REQUESTS_ERROR,
          message: "",
        })
      );
    } catch (error) {
      dispatch(
        setErrorAC({
          type: UPDATE_ACCOUNT_REQUESTS_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  createDataRequestTC: (accID, body, type) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await requestsAPI.createDataRequest(accID, body);
      if (response.status === 200 && response.data.success) {
        dispatch(addAccRequestAC({ type: [type], data: response.data.request }));
      }
      dispatch(
        setErrorAC({
          type: CREATE_DATA_REQUEST_ERROR,
          message: "",
        })
      );
    } catch (error) {
      dispatch(
        setErrorAC({
          type: CREATE_DATA_REQUEST_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getRiskSettingsMetadataTC: (accID) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await requestsAPI.getRSMetadata(accID);
      if (response.status === 200 && response.data.success) {
        dispatch(setRiskSettingsMetadataAC(response.data.items));
      }
      dispatch(
        setErrorAC({
          type: GET_RIST_SETTINGS_METADATA_ERROR,
          message: "",
        })
      );
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_RIST_SETTINGS_METADATA_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  updateRequestStatusTC:
    ({ accID, requestID, body, action }) => async (dispatch) => {
      try {
        dispatch(setLoadingAC(true));
        const actionResponse = await requestsAPI.setRequestStatusAction(
          requestID,
          body,
          action
        );
        if (actionResponse.status === 200 && actionResponse.data.success) {
          dispatch(updateAccRequestAC(actionResponse.data.request));

          if (action === 'send') {
            dispatch(chatThunks.getChatMessagesTC(accID, requestID));
          }
        }
        dispatch(
          setErrorAC({
            type: UPDATE_REQUEST_STATUS_ERROR,
            message: "",
          })
        );
      } catch (error) {
        dispatch(
          setErrorAC({
            type: UPDATE_REQUEST_STATUS_ERROR,
            message: error.response?.data?.message,
          })
        );
      } finally {
        dispatch(setLoadingAC(false));
      }
    },
};
