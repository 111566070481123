import { useState, createContext, useContext } from 'react';
import ReactDOM from 'react-dom';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const hideModal = () => {
    setVisibleModal(false);
    setModalContent(null);
  }

  const showModal = (content) => {
    if (content) {
      setModalContent(content)
    }
    setVisibleModal(true);
  }


  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {visibleModal && ReactDOM.createPortal(
        <>
          {modalContent}
        </>
        , document.getElementById('root')
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
