//auth
export const SET_IS_AUTHENTICATED = "AUTH_REDUCER/SET_IS_AUTHENTICATED";
export const SET_CURRENT_USER = "AUTH_REDUCER/SET_CURRENT_USER";
export const LOGOUT = "AUTH_REDUCER/LOGOUT";
export const TRIGGER_REDIRECT = "AUTH_REDUCER/TRIGGER_REDIRECT";
export const SET_REDIRECT_ITEM = "AUTH_REDUCER/SET_REDIRECT_ITEM";
export const SET_RECEIVED_MESSAGE = "AUTH_REDUCER/SET_RECEIVED_MESSAGE";
export const SET_INVITE_CREDS = "AUTH_REDUCER/SET_INVITE_CREDS";

//accounts
export const LIST_ACCOUNTS = "ACCOUNTS_REDUCER/LIST_ACCOUNTS";
export const SET_CURRENT_ACCOUNT = "ACCOUNTS_REDUCER/SET_CURRENT_ACCOUNT";
export const UPDATE_CURRENT_ACCOUNT = "ACCOUNTS_REDUCER/UPDATE_CURRENT_ACCOUNT";
export const SET_COUNTRIES = "ACCOUNTS_REDUCER/SET_COUNTRIES";
export const SET_STATUS_HISTORY = "ACCOUNTS_REDUCER/SET_STATUS_HISTORY";
export const SET_ACC_EXT = "ACCOUNTS_REDUCER/SET_ACC_EXT";
export const SET_WIRE_IMAGE = "ACCOUNTS_REDUCER/SET_WIRE_IMAGE";
export const SET_WIRES_LIST = "ACCOUNTS_REDUCER/SET_WIRES_LIST";
export const SET_ACC_REQUESTS = "ACCOUNTS_REDUCER/SET_ACC_REQUESTS";
export const ADD_ACC_REQUEST = "ACCOUNTS_REDUCER/ADD_ACC_REQUEST";
export const ADD_ACC_REQUESTS = "ACCOUNTS_REDUCER/ADD_ACC_REQUESTS";
export const UPDATE_ACC_REQUEST = "ACCOUNTS_REDUCER/UPDATE_ACC_REQUEST";
export const REMOVE_ACC_REQUEST = "ACCOUNTS_REDUCER/REMOVE_ACC_REQUEST";
export const SET_RS_METADATA = "ACCOUNTS_REDUCER/SET_RS_METADATA";
export const UPDATE_RS_METADATA = "ACCOUNTS_REDUCER/UPDATE_RS_METADATA";
export const SET_TOTAL_REQUEST_ITEMS = "ACCOUNTS_REDUCER/SET_TOTAL_REQUEST_ITEMS";

// socket
export const UPDATE_IS_INITIALIZE_SOCKET = "SOCKET_REDUCER/UPDATE_IS_INITIALIZE_SOCKET";
export const INITIALIZE_SOCKET = "SOCKET_MIDDLEWARE/INITIALIZE_SOCKET";
export const ADD_SOCKET_NAMESPACE = "SOCKET_MIDDLEWARE/ADD_SOCKET_NAMESPACE";
export const REMOVE_SOCKET_NAMESPACE = "SOCKET_MIDDLEWARE/REMOVE_SOCKET_NAMESPACE";

//chat
export const SET_CHAT_MESSAGE = "CHAT_REDUCER/SET_CHAT_MESSAGE";
export const SET_CHAT_MESSAGES = "CHAT_REDUCER/SET_CHAT_MESSAGES";
export const DELETE_CHAT_MESSAGES = "CHAT_REDUCER/DELETE_CHAT_MESSAGES";

//marketdata
export const SET_MARKETDATA_PROVIDERS = "MARKETDATA_REDUCER/SET_MARKETDATA_PROVIDERS";
export const SET_MARKET_BUNDLES = "MARKETDATA_REDUCER/SET_MARKET_BUNDLES";
export const SET_TRADING_PLATFORMS = "MARKETDATA_REDUCER/SET_TRADING_PLATFORMS";
export const SAVE_REQUESTED_MARKETS = "MARKETDATA_REDUCER/SAVE_REQUESTED_MARKETS";
export const UPDATE_REQUESTED_MARKETS = "MARKETDATA_REDUCER/UPDATE_REQUESTED_MARKETS";

//profile
export const SET_PROFILE = "PROFILE_REDUCER/SET_PROFILE";
export const SET_POPUP_STATE = "PROFILE_REDUCER/SET_POPUP_STATE";
export const UPDATE_PROFILE = "PROFILE_REDUCER/UPDATE_PROFILE";
export const SET_PROFILE_NOTIFICATIONS =
  "PROFILE_REDUCER/SET_PROFILE_NOTIFICATIONS";
export const SET_FIELD_IS_EDITING = "PROFILE_REDUCER/SET_FIELD_IS_EDITING";
export const SET_PORTAL_WORKFLOW_TYPE = "PROFILE_REDUCER/SET_PORTAL_WORKFLOW_TYPE";

export const SET_STATEMENTS = "STATEMENTS_REDUCER/SET_STATEMENTS";

//documents
export const SET_LIST_OF_DOCUMENTS = "DOCUMENTS_REDUCER/SET_LIST_OF_DOCUMENTS";
export const REMOVE_DOCUMENT_FROM_STORE =
  "DOCUMENTS_REDUCER/REMOVE_DOCUMENT_FROM_STORE";
export const SET_CURRENT_DOCUMENT = "DOCUMENTS_REDUCER/SET_CURRENT_DOCUMENT";
export const CREATE_DOCUMENT = "DOCUMENTS_REDUCER/CREATE_DOCUMENT";
export const UPDATE_DOCUMENT = "DOCUMENTS_REDUCER/UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DOCUMENTS_REDUCER/DELETE_DOCUMENT";
export const SET_ETB_DOCUMENT = "DOCUMENTS_REDUCER/SET_ETB_DOCUMENT";
export const SET_FRESHEST_ETB_DOCUMENT = "DOCUMENTS_REDUCER/SET_FRESHEST_ETB_DOCUMENT";

//supportive
export const SET_LOADING = "AUTH_REDUCER/SET_LOADING";
export const SET_PAGINATION_LOADING = "AUTH_REDUCER/SET_PAGINATION_LOADING";
export const GET_ERRORS = "ERROR_REDUCER/GET_ERRORS";
export const SET_TOAST_STATE = "ERROR_REDUCER/SET_TOAST_STATE";
export const SET_SIDEBAR_BLOCK_STATUS = "UTILS_REDUCER/SET_SIDEBAR_BLOCK_STATUS";
export const SET_CURRENT_PAGE_TITLE = "UTILS_REDUCER/SET_CURRENT_PAGE_TITLE";

export const SET_BURGER_MENU_OPENED = "NOTIFICATION_REDUCER/SET_BURGER_MENU_OPENED";

//administrative
export const SET_LOGINS = "ADMINISTRATIVE_REDUCER/SET_LOGINS";
export const SET_FILES_LIST = "ADMINISTRATIVE_REDUCER/SET_FILES_LIST";

//document attachments
export const RENAME_DOC_ATTACHMENT_ERROR = "documents/renameattachment";
export const GET_DOC_ATTACHMENT_ERROR = "documents/getattachment";
export const GET_DOC_ATTACHMENT_LIST_ERROR = "documents/getattachmentlist";
export const UPLOAD_DOC_ATTACHMENT_ERROR = "documents/uploadattachment";
export const REMOVE_DOC_ATTACHMENT_ERROR = "documents/removeattachment";
export const GET_PERMALINK_ERROR = "documents/getattachment";

//routes
export const TO_ENTER = "/enter/";
export const TO_LOGIN = "/login/";
export const TO_ADMIN_INVITES = "/adm-invite/";
export const TO_PROFILE = "/profile/";
export const TO_SIMULATOR = "/simulator/";
export const TO_ACCOUNTS_PAGE = "/accounts/";
export const TO_SUPPORT_PAGE = "/support/";
export const TO_STATEMENTS = "/statements/";
export const TO_DOCUMENTS_PAGE = "/documents/";
export const TO_ACCOUNT_SETUP = "/account-setup/";
export const TO_ACCOUNT_OVERVIEW = "/account-overview/";
export const TO_MARKET_DATA = "/market-data/";
export const TO_PERMALINK = "/l/";
export const TO_RESOURCES = "/etb";
export const TO_FAQ = "/faq";

// zimtra links
export const ABOUT_ZIMTRA = "https://zimtra.ky/";
export const FAQ = "https://zimtra.ky/faq/";
export const CONTACT_US = "https://zimtra.ky/contact/";
export const PRIVACY_POLICY = "https://zimtra.ky/privacy-policy/";

//  ************* error types ************

//auth error types
export const LOGIN_ERROR = "auth/login";
export const LOGOUT_ERROR = "auth/logout";
export const NEW_PASSWORD__ERROR = "auth/newpassword";
export const PASSWORD_RECOVERY_ERROR = "auth/passwordrecovery";
export const REGISTRATION_ERROR = "auth/registration";
export const INVITE_ERROR = "auth/invite";
export const VERIFY_ERROR = "auth/verify";
export const GET_INVITE_CREDS_ERROR = "auth/getinvcredserr";

//account error types
export const ACCOUNTS_LIST_ERROR = "accounts/list";
export const CURRENT_ACCOUNT_ERROR = "accounts/current";
export const UPDATE_ACCOUNT_ERROR = "accounts/update-acc";
export const UPDATE_ACC_REVIEW_ERROR = "accounts/update-acc-review";
export const SEND_ACCOUNT_SETUP_DATA_ERROR = "accounts/send-account-setup-data";
export const SET_ENTITY_ERROR = "accounts/set-entity";
export const UPDATE_ACC_STATUS_ERROR = "accounts/update-acc-status";
export const SET_ACC_STATUS_HISTORY_ERROR = "accounts/set-acc-status-history";
export const SET_ACC_EXT_ERROR = "accounts/set-acc-ext";
export const GET_ACC_WEBSOCKET_TOKEN_ERROR = "accounts/get-account-websocket-token";
export const SET_WIRE_LIST_ERROR = "accounts/set-acc-wire-list-err";
export const CLAIM_WIRE_ERROR = "accounts/acc-claim-wire";
export const SEND_WIRE_CONFIRMATION_IMAGE_ERROR = "accounts/acc-send-wire-conf-image";
export const GET_WIRE_CONFIRMATION_IMAGE_ERROR = "accounts/acc-get-wire-conf-image";
export const REMOVE_WIRE_CONFIRMATION_IMAGE_ERROR = "accounts/acc-remove-wire-conf-image";

//marketdata error types
export const GET_MARKETDATA_PROVIDERS_ERROR = "marketdata/get-marketdata-providers";
export const GET_TRADING_PLATFORMS_ERROR = "marketdata/get-trading-platforms";
export const UPDATE_CHANGE_MARKETS_REQUEST_ERROR = "marketdata/update-change-market-request";
export const CREATE_CHANGE_MARKET_REQUEST_ERROR = "marketdata/create-change-market-request";

//requests error types
export const GET_ACCOUNT_REQUESTS_ERROR = "requests/get-account-requests";
export const UPDATE_ACCOUNT_REQUESTS_ERROR = "requests/update-account-requests";
export const UPDATE_REQUEST_STATUS_ERROR = "requests/update-request-status";
export const CREATE_DATA_REQUEST_ERROR = "requests/create-data-requests";
export const GET_RIST_SETTINGS_METADATA_ERROR = "requests/get-risk-settings-metadata";

//account attachments error types
export const SET_ACCOUNT_ATTACHMENTS_ERROR = "ACCOUNT/SET_ACCOUNT_ATTACHMENTS_ERROR";
export const GET_ACCOUNT_ATTACHMENT_ERROR = "ACCOUNT/GET_ACCOUNT_ATTACHMENT_ERROR";

//documents error types
export const DOCUMENT_LIST_ERROR = "documents/list";
export const CURRENT_DOCUMENT_ERROR = "documents/current";
export const CREATE_DOCUMENT_ERROR = "documents/create";
export const EDIT_DOCUMENT_ERROR = "documents/edit";
export const DELETE_DOCUMENT_ERROR = "documents/delete";

//profile error types
export const CURRENT_PROFILE_ERROR = "profile/current";
export const UPDATE_PROFILE_ERROR = "profile/update";

//statements error types
export const GET_STATEMENTS_LIST_ERROR = "statement/get-statements-list";
export const GET_STATEMENTS_FILE_ERROR = "statement/get-statements-file";

//chat error types
export const GET_CHAT_MESSAGES_ERROR = "chat/get-chat-messages";
export const SEND_CHAT_MESSAGE_ERROR = "chat/send-chat-messages";

// socket error types
export const ADD_SOCKET_NAMESPACE_ERROR = "SOCKET_MIDDLEWARE/ADD_SOCKET_NAMESPACE_ERROR";

//administrative error types
export const GET_LOGINS_ERROR = "administrative/GET_LOGINS_ERROR";
export const SET_FILES_LIST_ERROR = "administrative/SET_FILES_LIST_ERROR";
export const GET_AND_DOWNLOAD_FILE_ERROR =
  "administrative/GET_AND_DOWNLOAD_FILE_ERROR";
export const UPLOAD_FILE_ERROR = "administrative/UPLOAD_FILE_ERROR";
export const DELETE_FILE_ERROR = "administrative/DELETE_FILE_ERROR";
export const SEND_INVITE_ERROR = "administrative/SEND_INVITE_ERROR";

//  ************* popup types ************

export const POPUP_NEW_PASSWORD = "popuptype/newpassword";
export const POPUP_EDIT_PROFILE = "popuptype/editprofile";
export const POPUP_SUPPORT = "popuptype/support";

//  ************* administrative popup types ************
export const ADM_ERROR_POPUP = "admpopuptype/error";
export const ADM_WARNING_POPUP = "admpopuptype/warning";
export const ADM_SUCCESS_POPUP = "admpopuptype/success";

//  ************* permission types ************
export const permissionTypes = {
  accounts: {
    ACCOUNT: "account",
    ACCOUNT_ACCOUNTS: "account.accounts",
    ACCOUNT_MANAGE: "account.manage",
    ACCOUNT_USERPROFILE: "account.userprofile",
    ACCOUNT_NOTIFICATION: "account.notifications",
    ACCOUNT_CHANGEPASSWORD: "account.changepassword",
  },
  support: {
    SUPPORT: "support",
    SUPPORT_REQUEST: "support.request",
  },
  document: {
    DOCUMENT: "document",
    DOCUMENT_PUBLIC: "document.public",
    DOCUMENT_EDITOR: "document.editor",
  },
  admin: {
    ADMIN: "admin",
    LOGINS: "admin.login",
    INVITE: "admin.invite",
    FS: {
      MAIN: "admin.fs",
      LIST: "admin.fs.list",
      DOWNLOAD: "admin.fs.download",
      UPLOAD: "admin.fs.upload",
      DELETE: "admin.fs.delete",
    },
  },
};

// tokens types
export const LOG_IN_TOKEN = "traderPortalToken";
export const ZIMTRA_REMEMBER_ME = "zimtra-cabinet-rememberme";

//account send data types
export const accountFetchTypes = {
  updateAccountInfo: 'updateAccountInfo',
  updateAccountReview: 'updateAccountReview',
  updateAccountStatus: "updateAccountStatus",
};

export const reviewStatuses = {
  OK: "ok",
  DEFICIENT: "deficient",
  UPDATED: "updated",
};
