import { UPDATE_IS_INITIALIZE_SOCKET } from "./types";

const initialState = {
  isInitializedSocket: false,
};

const socketsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_IS_INITIALIZE_SOCKET:
      return { ...state, isInitializedSocket: payload }
    default:
      return state;
  }
};

export default socketsReducer;
